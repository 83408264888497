export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_ROLES(state, payload) {
    const systemAdmin = payload.findIndex((element) => element.name === 'ROLE_SYSTEM_ADMIN');
    const data = payload;
    if (systemAdmin === 0) data.unshift();
    data.splice(systemAdmin, 1);
    state.roles = data;
  },
};
