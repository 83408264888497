export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_SEARCH_CLAUSE(state, payload) {
    state.searchClause = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_ACCOUNTS(state, payload) {
    state.advancedSearchParams.accounts = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_ACTIVITIES(state, payload) {
    state.advancedSearchParams.activities = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_CONTACTS(state, payload) {
    state.advancedSearchParams.contacts = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_DOCUMENTS(state, payload) {
    state.advancedSearchParams.documents = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_LEADS(state, payload) {
    state.advancedSearchParams.leads = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_OPPORTUNITIES(state, payload) {
    state.advancedSearchParams.opportunities = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_USERS(state, payload) {
    state.advancedSearchParams.users = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_TEAMS(state, payload) {
    state.advancedSearchParams.teams = payload;
  },

  SET_ADVANCED_SEARCH_PARAMS_DIVISIONS(state, payload) {
    state.advancedSearchParams.divisions = payload;
  },

  CLEAR_SEARCH_CLAUSE(state) {
    state.searchClause = '';
  },

  SET_SEARCH_RESULTS(state, payload) {
    state.searchResults = payload;
  },

  CLEAR_SEARCH_RESULTS(state) {
    state.searchResults = [];
  },

};
