import $http from '@/plugins/axios';

export default {
  /**
   * syncOutlookContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async syncOutlookContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/outlook/sync?accountId=${payload}`,
        method: 'GET',
      });
      // commit('SET_CONTACTS', response.data.data.outLook);
      return response.data;
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOutlookContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchOutlookContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts?page=${payload.page}&size=${payload.perPage}&outlookcontacts=true`,
        method: 'GET',
      });
      commit('SET_CONTACTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * importOutlookContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async importOutlookContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/accounts/${payload.accountId}`,
        data: payload.contacts,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_CONTACTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContact
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContact({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/${payload}`,
        method: 'GET',
      });
      commit('SET_CONTACT', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createContact
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createContact({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/contacts',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateContact
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateContact({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/${payload.id}/webapp`,
        data: payload,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteContact
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteContact({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/${payload}`,
        method: 'DELETE',
      });
      commit('DELETE_CONTACT', payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/accounts/${payload.accountId}/contacts?&page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_CONTACTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAccountContactList({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/data-grids/contacts/data-new?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createContactAccount
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createContactAccount({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/${payload.contactId}/accounts`,
        data: { accountId: payload.accountId },
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteContactAccount
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteContactAccount({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts/${payload.contactId}/accounts/${payload.accountId}`,
        method: 'DELETE',
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
