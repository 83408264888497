export default {
  isLoading: false,
  grid: {},
  drilldownGrid: {},
  lookupsGrid: {},
  customFieldsGrid: {},
  usersGoalsGrid: {},
  userGoalsGrid: {},
  goalTypesGrid: {},
  linkedIdsGrid: {},

};
