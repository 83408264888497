import $http from '@/plugins/axios';

export default {
  /**
   * fetchAccounts
   *
   * **Description**: Fetch all accounts
   *
   * @param {Func} { commit }
   * @param {Object} payload page | perPage
   * @return {JSON}
   */
  async fetchAccounts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/accounts?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACCOUNTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchActivities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchActivities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/contacts?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_CONTACTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeads({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/leadsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_LEADS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchOpportunities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchDocuments
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/documentsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
