export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_ACCOUNTS(state, payload) {
    state.accounts = payload;
  },

  SET_ACCOUNT(state, payload) {
    state.account = {
      ticker: payload.ticker,
      name: payload.name,
      owner: {
        id: payload.owners && payload.owners.length > 0 ? payload.owners[0].id : '',
        ownerId: payload.owners && payload.owners.length > 0 && payload.owners[0].owner
          ? payload.owners[0].owner.id : '',
        owner: payload.owners && payload.owners.length > 0 && payload.owners[0].owner
          ? `${payload.owners[0].owner.firstName} ${payload.owners[0].owner.lastName}` : '',
        percentage: payload.owners && payload.owners.length > 0 && payload.owners[0].owner
          ? payload.owners[0].percentage : 100,
      },
      coOwner: {
        id: payload.owners && payload.owners.length > 1 && payload.owners[1].owner
          ? payload.owners[1].id : '',
        ownerId: payload.owners && payload.owners.length > 1 && payload.owners[1].owner
          ? payload.owners[1].owner.id : '',
        owner: payload.owners && payload.owners.length > 1 && payload.owners[1].owner
          ? `${payload.owners[1].owner.firstName} ${payload.owners[1].owner.lastName}` : '',
        percentage: payload.owners && payload.owners.length > 1 && payload.owners[1].owner
          ? payload.owners[1].percentage : 0,
      },
      statusId: payload.statusId,
      typeId: payload.typeId,
      description: payload.description,
      dandBCompany: payload.dandBCompany,
      dunsNumber: payload.dunsNumber,
      naicsCode: payload.naicsCode,
      phone: payload.phone,
      fax: payload.fax,
      website: payload.website,
      parentId: payload.parentId,
      parent: payload.parentId ? payload.parent.name : null,
      externalId: payload.externalId,
      addressInformation: {
        street1: payload.addressInformation.street1,
        street2: payload.addressInformation.street2,
        street3: payload.addressInformation.street3,
        state: payload.addressInformation.state,
        city: payload.addressInformation.city,
        zip: payload.addressInformation.zip,
      },
      companyInformation: {
        industryId: payload.companyInformation.industry
          ? payload.companyInformation.industry.id : null,
        noOfEmployeesId: payload.companyInformation.noOfEmployees
          ? payload.companyInformation.noOfEmployees.id : null,
        annualRevenue: payload.companyInformation.annualRevenue,
      },
      isActive: payload.isActive,
      customFields: payload.customFieldEntries.map((item) => ({
        customFieldId: item.customField.id,
        customFieldEntryId: item.id,
        label: item.customField.label,
        value: item.value,
      })).sort((a, b) => (a.customFieldId - b.customFieldId)),
    };
  },

  SET_ACCOUNT_VIEW(state, payload) {
    state.accountView = payload;
  },

  SET_ACCOUNT_TICKER(state, payload) {
    state.account.ticker = payload;
  },

  SET_ACCOUNT_NAME(state, payload) {
    state.account.name = payload;
  },

  SET_ACCOUNT_OWNER_OWNER_ID(state, payload) {
    state.account.owner.id = payload;
  },

  SET_ACCOUNT_OWNER_ID(state, payload) {
    state.account.owner.ownerId = payload;
  },

  SET_ACCOUNT_OWNER(state, payload) {
    state.account.owner.owner = payload;
  },

  SET_ACCOUNT_OWNER_PERCENTAGE(state, payload) {
    state.account.owner.percentage = payload;
  },

  SET_ACCOUNT_CO_OWNER_OWNER_ID(state, payload) {
    state.account.coOwner.id = payload;
  },

  SET_ACCOUNT_CO_OWNER_ID(state, payload) {
    state.account.coOwner.ownerId = payload;
  },

  SET_ACCOUNT_CO_OWNER(state, payload) {
    state.account.coOwner.owner = payload;
  },

  SET_ACCOUNT_CO_OWNER_PERCENTAGE(state, payload) {
    state.account.coOwner.percentage = payload;
  },

  SET_ACCOUNT_PRIMARY_CONTACT_ID(state, payload) {
    state.account.primaryContactId = payload;
  },

  SET_ACCOUNT_STATUS(state, payload) {
    state.account.statusId = payload;
  },

  SET_ACCOUNT_TYPE_ID(state, payload) {
    state.account.typeId = payload;
  },

  SET_ACCOUNT_DESCRIPTION(state, payload) {
    state.account.description = payload;
  },

  SET_ACCOUNT_D_AND_B_COMPANY(state, payload) {
    state.account.dandBCompany = payload;
  },

  SET_ACCOUNT_DUNS_NUMBER(state, payload) {
    state.account.dunsNumber = payload;
  },

  SET_ACCOUNT_NAICS_CODE(state, payload) {
    state.account.naicsCode = payload;
  },

  SET_ACCOUNT_PHONE(state, payload) {
    state.account.phone = payload;
  },

  SET_ACCOUNT_FAX(state, payload) {
    state.account.fax = payload;
  },

  SET_ACCOUNT_WEBSITE(state, payload) {
    state.account.website = payload;
  },

  SET_ACCOUNT_PARENT_ID(state, payload) {
    state.account.parentId = payload;
  },

  SET_ACCOUNT_PARENT(state, payload) {
    state.account.parent = payload;
  },

  SET_ACCOUNT_EXTERNAL_ID(state, payload) {
    state.account.externalId = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_STREET_1(state, payload) {
    state.account.addressInformation.street1 = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_STREET_2(state, payload) {
    state.account.addressInformation.street2 = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_STREET_3(state, payload) {
    state.account.addressInformation.street3 = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_STATE(state, payload) {
    state.account.addressInformation.state = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_CITY(state, payload) {
    state.account.addressInformation.city = payload;
  },

  SET_ACCOUNT_ADDRESS_INFORMATION_ZIP(state, payload) {
    state.account.addressInformation.zip = payload;
  },

  SET_ACCOUNT_COMPANY_INFORMATION_INDUSTRY_ID(state, payload) {
    state.account.companyInformation.industryId = payload;
  },

  SET_ACCOUNT_COMPANY_INFORMATION_NO_OF_EMPLOYEES_ID(state, payload) {
    state.account.companyInformation.noOfEmployeesId = payload;
  },

  SET_ACCOUNT_COMPANY_INFORMATION_ANNUAL_REVENUE(state, payload) {
    state.account.companyInformation.annualRevenue = payload;
  },

  SET_ACCOUNT_IS_ACTIVE(state, payload) {
    state.account.isActive = payload;
  },

  SET_ACCOUNT_CUSTOM_FIELDS(state, payload) {
    state.account.customFields = payload.sort((a, b) => (a.customFieldId - b.customFieldId));
  },

  SET_ACCOUNT_OBJECT(state, payload) {
    state.account = payload;
  },

  SET_ACCOUNTS_GRID_CONFIGS(state, payload) {
    state.accountGridConfigs = payload;
  },

  CLEAR_EDIT_ACCOUNT(state) {
    state.account = {
      ticker: '',
      name: '',
      owner: {
        id: null,
        ownerId: '',
        owner: '',
        percentage: 100,
      },
      coOwner: {
        id: null,
        ownerId: '',
        owner: '',
        percentage: 0,
      },
      statusId: 103,
      typeId: null,
      description: '',
      dandBCompany: '',
      dunsNumber: '',
      naicsCode: '',
      phone: '',
      fax: '',
      website: '',
      industryIds: [],
      parentId: null,
      parent: '',
      externalId: '',
      addressInformation: {
        street1: '',
        street2: '',
        street3: '',
        state: '',
        city: '',
        zip: '',
      },
      companyInformation: {
        industryId: '',
        noOfEmployeesId: '',
        annualRevenue: 0,
      },
      isActive: true,
      customFields: [],
    };
  },

  CLEAR_ACCOUNT(state) {
    state.account = {
      ticker: '',
      name: '',
      owner: {
        id: null,
        ownerId: localStorage.getItem('user_id'),
        owner: localStorage.getItem('full_name'),
        percentage: 100,
      },
      coOwner: {
        id: null,
        ownerId: '',
        owner: '',
        percentage: 0,
      },
      statusId: 103,
      typeId: null,
      description: '',
      dandBCompany: '',
      dunsNumber: '',
      naicsCode: '',
      phone: '',
      fax: '',
      website: '',
      industryIds: [],
      parentId: null,
      parent: '',
      externalId: '',
      addressInformation: {
        street1: '',
        street2: '',
        street3: '',
        state: '',
        city: '',
        zip: '',
      },
      companyInformation: {
        industryId: '',
        noOfEmployeesId: '',
        annualRevenue: 0,
      },
      isActive: true,
      customFields: [],
    };
  },

  SET_INFLUENCER_TYPES(state, payload) {
    state.influencerTypes = payload;
  },

  CLEAR_ACCOUNTS(state) {
    state.accounts = [];
  },
};
