export default {
  getLoading: (state) => state.loading,

  getReadPermissions: (state) => state.readPermissions?.canEdit || null,

  getOpportunity: (state) => {
    const opportunity = { ...state.opportunity };
    return opportunity;
  },

  getOpportunityProjectFolders: (state) => state.opportunity.projectFolderId,

  getOpportunityInfluencers: (state) => {
    const influencers = [...state.opportunity.influencers];
    return influencers;
  },

  getOpportunityInfluencersView: (state) => {
    const { influencers } = state.opportunityView;
    return influencers || [];
  },

  getOpportunities: (state) => {
    const opportunities = [...state.opportunities];
    return opportunities;
  },

  getInfluencing: (state) => {
    const influencing = [...state.influencing];
    return influencing;
  },

  getDrilldownOpportunities: (state) => {
    const drilldownOpportunities = [...state.drilldownOpportunities];
    return drilldownOpportunities;
  },

  getStageOpportunities: (state) => {
    const stageOpportunities = [...state.stageOpportunities];
    return stageOpportunities;
  },

  getTotalOpportunitiesAmount: (state) => state.totalOpportunitiesAmount,

  getTotalClosedSales: (state) => state.totalClosedSales,

  getTotalClosedSalesAmount: (state) => state.totalClosedSalesAmount,

  getOpportunityView: (state) => {
    const opportunityView = { ...state.opportunityView };
    return opportunityView;
  },

  getOpportunityReasonStages: (state) => {
    const opportunityView = [...state.reasonStages];
    return opportunityView;
  },

  getOpportunityStageReasons: (state) => {
    const opportunityView = [...state.stageReasons];
    return opportunityView;
  },
};
