export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_USERS_ACTIVITY(state, payload) {
    state.usersActivity = payload;
  },

  SET_PAGES(state, payload) {
    state.pages = payload;
  },
};
