export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_ACCOUNTS(state, payload) {
    state.accounts = payload;
  },

  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },

  SET_LEADS(state, payload) {
    state.leads = payload;
  },

  SET_OPPORTUNITIES(state, payload) {
    state.opportunities = payload;
  },

  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },

  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },

};
