export default {
  SET_LEADS_VS_OPPORTUNITIES_BY_INDUSTRY(state, payload) {
    state.leadsVsOpportunitiesByIndustry = payload;
  },

  SET_LEADS_BY_ACCOUNT(state, payload) {
    state.leadsByAccount = payload;
  },

  SET_LEADS_BY_STAGE(state, payload) {
    state.leadsByStage = payload;
  },

  SET_LEADS_BY_SOURCE(state, payload) {
    state.leadsBySource = payload;
  },

  SET_OPEN_OPPORTUNITIES_BY_SALESPERSON(state, payload) {
    state.openOpportunitiesBySalesperson = payload;
  },

  SET_OPPORTUNITY_DATE_STATUS(state, payload) {
    state.opportunityDateStatus = payload;
  },

  SET_POTENTIAL_REVENUE_FORECAST(state, payload) {
    state.potentialRevenueForecast = payload;
  },

  SET_TOP_TEN_ACCOUNTS_BY_EXPECTED_REVENUE(state, payload) {
    state.topTenAccountsByExpectedRevenue = payload;
  },

  SET_WEIGHTED_REVENUE_BY_STAGE(state, payload) {
    state.weightedRevenueByStage = payload
      .sort((a, b) => b.orderSequence - a.orderSequence)
      .reverse();
  },

  SET_YTD_SALES_VS_GOALS(state, payload) {
    state.ytdSalesVsGoals = payload;
  },

  SET_TEAMS(state, payload) {
    const teamIds = payload.map((el) => el.id);
    localStorage.setItem('team_ids', JSON.stringify(teamIds));
    state.teams = payload;
  },

  SET_DIVISIONS(state, payload) {
    const divisionIds = payload.map((el) => el.id);
    localStorage.setItem('divisions_ids', JSON.stringify(divisionIds));
    state.divisions = payload;
  },

};
