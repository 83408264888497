import $http from '@/plugins/axios';

export default {
  /**
   * fetchUsersActivity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchUsersActivity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({
        url: `/user/fetchUserActivity?id=${payload}`,
        method: 'GET',
      });
      commit('SET_USERS_ACTIVITY', response.data.message);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchPages
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchPages({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Analytics({ url: '/', methods: 'POST' });
      commit('SET_PAGES', response.data.data);
      return response;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error;
    }
  },
};
