export default {
  getLoading: (state) => state.isLoading,

  getCredentials: (state) => {
    const credentials = { ...state.credentials };
    return credentials;
  },

  getLookupItems: (state) => {
    const lookupItems = [...state.lookups.lookup.lookupItems];
    return lookupItems;
  },

  getLookupsList: (state) => {
    const lookupItems = [...state.lookups.lookups];
    return lookupItems;
  },

  getCustomField: (state) => {
    const customField = { ...state.customFields.customField };
    return customField;
  },

  getLeadOppCustomFields: (state) => {
    const customFields = [...state.customFields.leadOppCustomFields];
    return customFields;
  },

  getAccountCustomFields: (state) => {
    const customFields = [...state.customFields.accountCustomFields];
    return customFields;
  },

  getContactCustomFields: (state) => {
    const customFields = [...state.customFields.contactCustomFields];
    return customFields;
  },

  getGoalType: (state) => {
    const goalType = { ...state.goalTypes.goalType };
    return goalType;
  },

  getGoalTypes: (state) => {
    const goalTypes = [...state.goalTypes.goalTypes];
    return goalTypes;
  },

  getLinkedId: (state) => {
    const linkedId = { ...state.linkedIds.linkedId };
    return linkedId;
  },

  getLinkedIds: (state) => {
    const linkedIds = [...state.linkedIds.linkedIds];
    return linkedIds;
  },

  getGoalPeriods: (state) => {
    const goalPeriods = [...state.goalPeriods];
    return goalPeriods;
  },

  getIsWidgetsOpen: (state) => {
    const { isWidgetsOpen } = state;
    return isWidgetsOpen;
  },

  getCustomizations: (state) => {
    const { customizations } = state;
    return customizations;
  },

  getAllowSalesPeopleExport: (state) => {
    const { allowSalesPeopleExport } = state.settings;
    return allowSalesPeopleExport;
  },

  getSettings: (state) => state.settings,
};
