import axios from 'axios';
import router from '@/router';

import getConfig from '@/plugins/config';

// setup environment variables dynamically
const VUE_APP_CLIENT_ID = getConfig('VUE_APP_CLIENT_ID');
const VUE_APP_CLIENT_SECRET = getConfig('VUE_APP_CLIENT_SECRET');
const VUE_APP_AUTH_SERVER_API_BASE_URL = getConfig('VUE_APP_AUTH_SERVER_API_BASE_URL');
const VUE_APP_ORGANIZATION_SERVER_API_BASE_URL = getConfig(
  'VUE_APP_ORGANIZATION_SERVER_API_BASE_URL',
);
const VUE_APP_LEADS_SERVER_API_BASE_URL = getConfig('VUE_APP_LEADS_SERVER_API_BASE_URL');
const VUE_APP_LOOKUPS_SERVER_API_BASE_URL = getConfig('VUE_APP_LOOKUPS_SERVER_API_BASE_URL');
const VUE_APP_GRIDS_SERVER_API_BASE_URL = getConfig('VUE_APP_GRIDS_SERVER_API_BASE_URL');
const VUE_APP_SEARCH_SERVER_API_BASE_URL = getConfig('VUE_APP_SEARCH_SERVER_API_BASE_URL');

const basicToken = Buffer.from(
  `${VUE_APP_CLIENT_ID}:${VUE_APP_CLIENT_SECRET}`,
).toString('base64');

const $http = {
  Auth: axios.create({
    baseURL: VUE_APP_AUTH_SERVER_API_BASE_URL,
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  }),

  Security: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Users: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Organization: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Leads: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Lookup: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Lookups: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Search: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Grids: axios.create({
    baseURL: '',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),
};

const getAccessToken = () => localStorage.getItem('access_token');
const getOrganizationId = () => localStorage.getItem('organization_id');

const securityInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_AUTH_SERVER_API_BASE_URL}/api/roles`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Security.interceptors.request.use(securityInterceptor);

$http.Security.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const usersInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_AUTH_SERVER_API_BASE_URL}/api`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Users.interceptors.request.use(usersInterceptor);

$http.Users.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const organizationInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_ORGANIZATION_SERVER_API_BASE_URL}/${getOrganizationId()}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Organization.interceptors.request.use(organizationInterceptor);

$http.Organization.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const leadsInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_LEADS_SERVER_API_BASE_URL}/${getOrganizationId()}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Leads.interceptors.request.use(leadsInterceptor);

$http.Leads.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const lookupsInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_LOOKUPS_SERVER_API_BASE_URL}/${getOrganizationId()}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

const lookupInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_LOOKUPS_SERVER_API_BASE_URL}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Lookup.interceptors.request.use(lookupInterceptor);

$http.Lookup.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

$http.Lookups.interceptors.request.use(lookupsInterceptor);

$http.Lookups.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const searchInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_SEARCH_SERVER_API_BASE_URL}/${getOrganizationId()}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Search.interceptors.request.use(searchInterceptor);

$http.Search.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

const gridsInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.baseURL = `${VUE_APP_GRIDS_SERVER_API_BASE_URL}`;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Grids.interceptors.request.use(gridsInterceptor);

$http.Grids.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push('/unauthorized');
    } else if (error.response.status === 500) {
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  },
);

export default $http;
