import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    isLoading: false,
    isDrilldownLoading: false,
    accounts: [],
    pageMeta: {
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    leadsPageMeta: {
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    contactsPageMeta: {
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    activities: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    activitiesList: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    contacts: [],

    leads: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    leadsDrilldown: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    opportunities: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    opportunitiesDrilldown: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    influencers: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    documents: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    users: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    customFields: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    lookups: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    usersGoals: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    userGoals: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    goalTypes: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },

    linkedIds: {
      content: [],
      numberOfElements: 0,
      totalElements: 0,
      totalPage: 0,
      number: 0,
    },
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getDrilldownLoading: (state) => state.isDrilldownLoading,
    getAccounts: (state) => state.accounts,
    getPageMeta: (state) => state.pageMeta,
    getActivities: (state) => state.activities,
    getActivitiesList: (state) => state.activitiesList,
    getContacts: (state) => state.contacts,
    getLeads: (state) => state.leads,
    getLeadsDrilldown: (state) => state.leadsDrilldown,
    getOpportunities: (state) => state.opportunities,
    getOpportunitiesDrilldown: (state) => state.opportunitiesDrilldown,
    getInfluencers: (state) => state.influencers,
    getDocuments: (state) => state.documents,
    getUsers: (state) => state.users,
    getCustomFields: (state) => state.customFields,
    getLookups: (state) => state.lookups,
    getUsersGoals: (state) => state.usersGoals,
    getUserGoals: (state) => state.userGoals,
    getGoalTypes: (state) => state.goalTypes,
    getLinkedIds: (state) => state.linkedIds,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_DRILLDOWN_LOADING(state, payload) {
      state.isDrilldownLoading = payload;
    },

    SET_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },
    SET_CONTACTS_PAGE_META(state, payload) {
      state.pageMeta = payload;
    },

    SET_PAGE_META(state, payload) {
      state.pageMeta = payload;
    },

    SET_LEADS_PAGE_META(state, payload) {
      state.pageMeta = payload;
    },

    CLEAR_ACCOUNTS(state) {
      state.accounts = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_ACTIVITIES(state, payload) {
      state.activities = payload;
    },

    CLEAR_ACTIVITIES(state) {
      state.activities = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_ACTIVITIES_LIST(state, payload) {
      state.activitiesList = payload;
    },

    CLEAR_ACTIVITIES_LIST(state) {
      state.activitiesList = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },

    SET_CONTACTS_CONTENT(state, payload) {
      state.contacts.content = payload;
    },

    CLEAR_CONTACTS(state) {
      state.contacts = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_LEADS(state, payload) {
      state.leads = payload;
    },

    SET_LEADS_CONTENT(state, payload) {
      state.leads.content = payload;
    },

    CLEAR_LEADS(state) {
      state.leads = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_LEADS_DRILLDOWN(state, payload) {
      state.leadsDrilldown = payload;
    },

    SET_LEADS_DRILLDOWN_CONTENT(state, payload) {
      state.leadsDrilldown.content = payload;
    },

    CLEAR_LEADS_DRILLDOWN(state) {
      state.leadsDrilldown = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_OPPORTUNITIES(state, payload) {
      state.opportunities = payload;
    },

    SET_OPPORTUNITIES_CONTENT(state, payload) {
      state.opportunities.content = payload;
    },

    SET_INFLUENCERS(state, payload) {
      state.influencers = payload;
    },

    CLEAR_OPPORTUNITIES(state) {
      state.opportunities = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_OPPORTUNITIES_DRILLDOWN(state, payload) {
      state.opportunitiesDrilldown = payload;
    },

    SET_OPPORTUNITIES_DRILLDOWN_CONTENT(state, payload) {
      state.opportunitiesDrilldown.content = payload;
    },

    CLEAR_OPPORTUNITIES_DRILLDOWN(state) {
      state.opportunitiesDrilldown = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_DOCUMENTS(state, payload) {
      state.documents = payload;
    },

    CLEAR_DOCUMENTS(state) {
      state.documents = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_USERS(state, payload) {
      state.users = payload;
    },

    CLEAR_USERS(state) {
      state.users = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_CUSTOM_FIELDS(state, payload) {
      state.customFields = payload;
    },

    CLEAR_CUSTOM_FIELDS(state) {
      state.customFields = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_LOOKUPS(state, payload) {
      state.lookups = payload;
    },

    CLEAR_LOOKUPS(state) {
      state.lookups = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_USERS_GOALS(state, payload) {
      state.usersGoals = payload;
    },

    SET_USER_GOALS(state, payload) {
      state.userGoals = payload;
    },

    CLEAR_USERS_GOALS(state) {
      state.usersGoals = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    CLEAR_USER_GOALS(state) {
      state.userGoals = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_GOAL_TYPES(state, payload) {
      state.goalTypes = payload;
    },

    CLEAR_GOAL_TYPES(state) {
      state.goalTypes = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },

    SET_LINKED_IDS(state, payload) {
      state.linkedIds = payload;
    },

    CLEAR_LINKED_IDS(state) {
      state.linkedIds = {
        content: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPage: 0,
        number: 0,
      };
    },
  },

  actions: {
    async fetchCustomColumns() {
      try {
        const response = await $http.Organization({
          url: '/custom-fields-list',
          method: 'GET',
        });
        localStorage.setItem('custom_fields', JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    },

    async fetchAccounts({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/accounts/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_ACCOUNTS', response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchPageMeta({ commit }, payload) {
      try {
        const response = await $http.Organization({
          url: `/data-grids/accounts/pageMeta?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_PAGE_META', response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchContactsPageMeta({ commit }, payload) {
      try {
        const response = await $http.Organization({
          url: `/data-grids/contacts/pageMeta?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_CONTACTS_PAGE_META', response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchLeadsPageMeta({ commit }, payload) {
      try {
        const response = await $http.Organization({
          url: `/data-grids/leads/pageMeta?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LEADS_PAGE_META', response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchActivities({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/activities/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_ACTIVITIES', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchActivitiesList({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/activities/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}`,
          method: 'GET',
        });
        commit('SET_ACTIVITIES_LIST', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchContacts({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/contacts/data-new?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_CONTACTS', response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchLeads({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/leads/data-new?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LEADS', response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchLeadsDrilldown({ commit }, payload) {
      commit('SET_DRILLDOWN_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/leads/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LEADS_DRILLDOWN', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_DRILLDOWN_LOADING', false);
      }
    },

    async fetchOpportunities({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/opportunities/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_OPPORTUNITIES', response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchOpportunitiesDrilldown({ commit }, payload) {
      commit('SET_DRILLDOWN_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/opportunities/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_OPPORTUNITIES_DRILLDOWN', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_DRILLDOWN_LOADING', false);
      }
    },

    async fetchInfluencers({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/influencers/data?opportunityId=${payload.opportunityId}&page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_INFLUENCERS', response.data);
        return response.data.content;
      } catch (error) {
        throw console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchDocuments({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/documents/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_DOCUMENTS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUsers({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/users/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_USERS', response.data);
        return response.data.content;
      } catch (error) {
        throw console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchCustomFields({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/custom-fields?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_CUSTOM_FIELDS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchLookups({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Lookups({
          url: `/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LOOKUPS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUsersGoals({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/goal-items/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_USERS_GOALS', response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUserGoals({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/goal-items/data?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_USER_GOALS', response.data.data);
        return response.data.data;
      } catch (error) {
        throw console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchGoalTypes({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/goal-types?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_GOAL_TYPES', response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchLinkedIds({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/sales-ids/?page=${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LINKED_IDS', response.data);
        return response.data;
      } catch (error) {
        throw console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};
