export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_USER_ID(state, payload) {
    state.userId = payload;
  },

  SET_TEAMS(state, payload) {
    state.teams = payload;
  },

  ADD_TEAM(state, payload) {
    state.teams.push(payload);
  },

  UPDATE_TEAM(state, payload) {
    const index = state.teams.findIndex((team) => team.id === payload.id);
    state.teams.splice(index, 1, payload);
  },

  DELETE_TEAM(state, payload) {
    const index = state.teams.findIndex((team) => team.id === payload);
    if (index === 0) state.teams.shift();
    state.teams.splice(index, 1);
  },

  ADD_TEAM_MEMBER(state, payload) {
    const index = state.teams.findIndex((team) => team.id === payload.teamId);
    if (state.teams[index].members) {
      state.teams[index].members.push(payload.member);
    } else {
      state.teams[index].members = [payload.member];
    }
  },

  REMOVE_TEAM_MEMBER(state, payload) {
    const teamIndex = state.teams.findIndex((team) => team.id === payload.teamId);
    const memberIndex = state.teams[teamIndex].members.findIndex(
      (member) => member.id === payload.memberId,
    );
    state.teams[teamIndex].members.splice(memberIndex, 1);
  },

  SET_TEAM(state, payload) {
    state.team.id = payload.id;
    state.team.name = payload.name;
  },

  SET_TEAM_NAME(state, payload) {
    state.team.name = payload;
  },

  CLEAR_TEAM(state) {
    state.team = {
      id: '',
      name: '',
    };
  },
};
