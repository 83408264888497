import $http from '@/plugins/axios';

export default {
  /**
     * fetchProjectFolders
     *
     * @param {*} { commit }
     * @param {Object} payload
     */
  async fetchProjectFolders({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        // url: `/project-folders?page=0&size=10&title=${payload}&projectid=${payload}`,
        url: `/project-folders?title=${payload}&projectid=${payload}`,
        method: 'GET',
      });
      commit('SET_PROJECT_FOLDERS', response.data);
      return response.data;
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * createProjectFolder
     *
     * @param {*} { commit }
     * @param {*} payload
     */
  async createProjectFolder({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      await $http.Leads({
        url: `/project-folders/${payload.projectFolderId}/${payload.opportunityId}`,
        method: 'POST',
      });
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * removeProjectFolder
     *
     * @param {*} { commit }
     * @param {*} payload
     */
  async removeProjectFolder({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      await $http.Leads({
        url: `/project-folders/${payload.projectFolderId}/${payload.opportunityId}`,
        method: 'DELETE',
      });
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
