export default {
  isLoading: false,

  organization: {
    isActive: true,
    name: '',
    primaryUserId: '',
    primaryUser: {},
    timeZoneId: '',
    defaultTimezone: {},
    street1: '',
    street2: '',
    street3: '',
    city: '',
    state: '',
    zipCode: '',
    locations: [],
    createdAt: '',
    updatedAt: '',
  },

  stats: {
    activeAccountsCount: 0,
    leadsCount: 0,
    contactsCount: 0,
    opportunitiesCount: 0,
    openActivitiesCount: 0,
    recentAccounts: [],
    lastOpportunity: {},
    lastLead: {},
    lastContact: {},
  },

  userLinkedIds: [],

  usersGoals: {
    userId: null,
    year: null,
    fiscalYear: null,
    usersGoals: {
      sales: null,
      booking: null,
      salesGp: null,
      bookingGp: null,
    },
    goalType: {},
    goalTotal: null,
    periodGoalTotal: null,
    distribution: [],
    userName: ' ',
    linkedSalesIds: '',
  },
};
