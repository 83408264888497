export default {
  getLoading: (state) => state.isLoading,

  getOrderQuoteFinancials: (state) => {
    const orderQuoteFinancials = [...state.orderQuoteFinancials];
    return orderQuoteFinancials;
  },

  getAccountOrderQuoteFinancials: (state) => {
    const orderQuoteFinancials = [...state.accountOrderQuoteFinancials];
    return orderQuoteFinancials;
  },

  getOrderQuoteFinancialsSummation: (state) => {
    const data = [...state.orderQuoteFinancialsSummation];
    if (data.length > 0) {
      return data[0];
    }
    return [];
  },
};
