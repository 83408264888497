import '@/plugins/vee-validate';
import Buefy from 'buefy';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import exportingInit from 'highcharts/modules/exporting';
import Funnel from 'highcharts/modules/funnel';
import offlineExporting from 'highcharts/modules/offline-exporting';
import moment from 'moment';
import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';
import JsonExcel from 'vue-json-excel';
import VueTelInput from 'vue-tel-input';
import Vue2Filters from 'vue2-filters';
import AuthorizationMixin from '@/mixins/AuthorizationMixin';
import ExportMixin from '@/mixins/ExportMixin';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(Buefy, { defaultLocale: 'en-US' });

Funnel(Highcharts);
exportingInit(Highcharts);
offlineExporting(Highcharts);
Vue.use(HighchartsVue);

const telGlobalOptions = {
  autoFormat: true,
  autoDefaultCountry: false,
  defaultCountry: 'US',
};

Vue.use(VueTelInput, telGlobalOptions);

const Vue2FiltersConfig = {
  currency: {
    decimalDigits: 0,
  },
};
Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'USD', locale: 'en' },
});

Vue.component('downloadExcel', JsonExcel);

// global mixins
Vue.mixin(AuthorizationMixin);
Vue.mixin(ExportMixin);

Vue.prototype.moment = moment;

// global date filter
Vue.filter('date', (value) => (value ? moment.utc(value).format('MM/DD/YYYY') : null));
Vue.filter('dateTime', (value) => {
  if (value) {
    const dateTime = new Date(value);

    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    }).format(dateTime);
  }
  return null;
});
Vue.filter('time', (value) => new Date(value).toLocaleTimeString());

// const MyComponent = Vue.component('contacts-form');
Vue.directive('click-outside-dg', {
  bind: (el, binding, vnode) => {
    const clickOutsideEvent = (event) => {
      let excluded = [
        'inlineEditingSavePrompt',
        'saveEntryButton',
        'cancelEntryButton',
      ];

      const { handler, exclude } = binding.value;

      if (handler && exclude) {
        excluded = exclude;
      }

      let clickedOnExcludedEl = false;

      excluded.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];

          if (excludedEl) {
            clickedOnExcludedEl = (excludedEl.$el.contains(event.target)
            || excludedEl.$el === event.target);
          }
        }
      });

      if (!(el === event.target || el.contains(event.target)) && clickedOnExcludedEl === false) {
        // event.stopPropagation();
        if (handler && exclude) {
          vnode.context[handler]();
        } else {
          vnode.context[binding.expression]();
        }
      }
    };

    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = clickOutsideEvent;

    document.body.addEventListener('click', clickOutsideEvent);
  },
  unbind: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = null;
  },
});

Vue.directive('multi-select-field', {
  bind: (el, binding, vnode) => {
    vnode.context.$on('finished-mapping', () => {
      const { index, field, handler } = binding.value;

      if (handler) {
        vnode.context[handler](index, field);
      }
    });
  },
  unbind: (el, binding, vnode) => {
    vnode.context.$off('finished-mapping');
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
