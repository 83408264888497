export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_CUSTOMIZATIONS(state, payload) {
    state.customizations = payload;
  },

  SET_READ_CUSTOMIZATIONS(state, payload) {
    state.customizations.allRead = payload;
  },

  SET_ALLOW_SALESPEOPLE_EXPORT(state, payload) {
    state.customizations.allowSalespeopleExport = payload;
  },

  SET_FISCAL_YEAR_START(state, payload) {
    state.customizations.fiscalStartMonth = payload;
  },

  SET_CREDENTIALS_CLIENT_ID(state, payload) {
    state.credentials.clientId = payload;
  },

  SET_CREDENTIALS_TENANT_ID(state, payload) {
    state.credentials.tenantId = payload;
  },

  SET_CREDENTIALS_SECRET_KEY(state, payload) {
    state.credentials.secretKey = payload;
  },

  CLEAR_CREDENTIALS(state) {
    state.credentials = {
      clientId: '',
      tenantId: '',
      secretKey: '',
    };
  },

  SET_ACC_CUSTOM_FIELDS(state, payload) {
    state.customFields.accountCustomFields = payload.map((i) => {
      let modifiedItem = {
        ...i,
        lookups: i.lookups
          .sort((a, b) => a.value.localeCompare(b.value))
          .sort((a, b) => a.orderSequence - b.orderSequence),
      };

      if (i.type === 'CURRENCY') {
        modifiedItem = { ...modifiedItem, value: i.value || 0 };
      }

      return modifiedItem;
    }).sort((a, b) => a.id - b.id);
  },

  SET_CONTACT_CUSTOM_FIELDS(state, payload) {
    state.customFields.contactCustomFields = payload.map((i) => {
      let modifiedItem = {
        ...i,
        lookups: i.lookups
          .sort((a, b) => a.value.localeCompare(b.value))
          .sort((a, b) => a.orderSequence - b.orderSequence),
      };

      if (i.type === 'CURRENCY') {
        modifiedItem = { ...modifiedItem, value: i.value || 0 };
      }

      return modifiedItem;
    }).sort((a, b) => a.id - b.id);
  },

  SET_LEAD_OPP_CUSTOM_FIELDS(state, payload) {
    state.customFields.leadOppCustomFields = payload.map((i) => {
      let modifiedItem = {
        ...i,
        lookups: i.lookups
          .sort((a, b) => a.value.localeCompare(b.value))
          .sort((a, b) => a.orderSequence - b.orderSequence),
      };

      if (i.type === 'CURRENCY') {
        modifiedItem = { ...modifiedItem, value: i.value || 0 };
      }

      return modifiedItem;
    }).sort((a, b) => a.id - b.id);
  },

  SET_CUSTOM_FIELD(state, payload) {
    state.customFields.customField = {
      label: payload.label,
      objectName: payload.objectName,
      isRequired: payload.isRequired,
      type: payload.type,
      lookupName: payload.lookupName,
    };
  },

  SET_FIELD_NAME(state, payload) {
    state.customFields.customField.label = payload;
  },

  SET_FIELD_OBJECT_NAME(state, payload) {
    state.customFields.customField.objectName = payload;
  },

  SET_FIELD_REQUIRED(state, payload) {
    state.customFields.customField.isRequired = payload;
  },

  SET_FIELD_TYPE(state, payload) {
    state.customFields.customField.type = payload;
  },

  SET_FIELD_LOOKUPS(state, payload) {
    state.customFields.customField.lookupName = payload;
  },

  SET_LOOKUP_NAME(state, payload) {
    state.lookups.lookup.lookupName = payload;
  },

  SET_LOOKUPS(state, payload) {
    state.lookups.lookups = payload;
  },

  SET_LOOKUP_ITEMS(state, payload) {
    state.lookups.lookup.lookupItems = payload;
  },

  SET_LINKED_ID(state, payload) {
    state.linkedIds.linkedId = payload;
  },

  SET_LINKED_IDS(state, payload) {
    state.linkedIds.linkedIds = payload;
  },

  SET_LINKED_ID_SALES_ID(state, payload) {
    state.linkedIds.linkedId.salesID = payload;
  },

  SET_LINKED_ID_DESCRIPTION(state, payload) {
    state.linkedIds.linkedId.description = payload;
  },

  SET_LINKED_ID_USER_ID(state, payload) {
    state.linkedIds.linkedId.userId = payload;
  },

  CLEAR_LINKED_ID(state) {
    state.linkedIds.linkedId = {
      salesID: '',
      description: '',
      userId: null,
    };
  },

  CLEAR_LINKED_IDS(state, payload) {
    state.linkedIds.linkedIds = payload;
  },

  CLEAR_LOOKUP(state) {
    state.lookups.lookup = {
      lookupName: '',
      lookupItems: [],
    };
  },

  CLEAR_CUSTOM_FIELD(state) {
    state.customFields.customField = {
      label: '',
      objectName: '',
      isRequired: false,
      type: '',
      lookupName: null,
    };
  },

  SET_GOAL_TYPE(state, payload) {
    state.goalTypes.goalType = payload;
  },

  SET_GOAL_TYPE_NAME(state, payload) {
    state.goalTypes.goalType.type = payload;
  },

  SET_GOAL_TYPE_DEFAULT(state, payload) {
    state.goalTypes.goalType.isDefault = payload;
  },

  SET_GOAL_TYPES(state, payload) {
    state.goalTypes.goalTypes = payload;
  },

  CLEAR_GOAL_TYPES(state) {
    state.goalTypes.goalTypes = [];
  },

  CLEAR_GOAL_TYPE(state) {
    state.goalTypes.goalType = {
      type: '',
      isDefault: false,
    };
  },

  SET_GOAL_PERIODS(state, payload) {
    state.goalPeriods = payload;
  },

  SET_WIDGETS_OPEN(state, payload) {
    state.isWidgetsOpen = payload;
  },

  SET_SETTINGS(state, payload) {
    state.settings = { ...payload };
  },
};
