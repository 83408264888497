import $http from '@/plugins/axios';

export default {
  /**
   * fetchTeams
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchTeams({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/teams?page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_TEAMS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createTeam
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createTeam({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/teams',
        data: { name: payload },
        method: 'POST',
      });
      commit('ADD_TEAM', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateTeam
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateTeam({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/teams/${payload.id}`,
        data: { name: payload.name },
        method: 'PUT',
      });
      commit('UPDATE_TEAM', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteTeam
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteTeam({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({ url: `/teams/${payload}`, method: 'DELETE' });
      commit('DELETE_TEAM', payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * addTeamMember
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async addTeamMember({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/teams/${payload.teamId}/members`,
        data: { userId: payload.userId },
        method: 'POST',
      });
      commit('SET_USER_ID', '');
      commit('ADD_TEAM_MEMBER', { teamId: payload.teamId, member: response.data.data });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteTeamMember
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteTeamMember({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/teams/${payload.teamId}/members/${payload.userId}`,
        method: 'DELETE',
      });
      commit('REMOVE_TEAM_MEMBER', { teamId: payload.teamId, memberId: payload.userId });
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
