export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_ORGANIZATION(state, payload) {
    state.organization = payload;
  },

  SET_USER_ID(state, payload) {
    state.usersGoals.userId = payload;
  },

  SET_USER_NAME(state, payload) {
    state.usersGoals.userName = payload;
  },

  SET_YEAR(state, payload) {
    state.usersGoals.year = payload;
  },

  SET_FISCAL_YEAR(state, payload) {
    state.usersGoals.fiscalYear = payload;
  },

  SET_USER_GOALS_SALES(state, payload) {
    state.usersGoals.usersGoals.sales = payload;
  },

  SET_USER_GOALS_SALESGP(state, payload) {
    state.usersGoals.usersGoals.salesGp = payload;
  },

  SET_USER_GOALS_BOOKING(state, payload) {
    state.usersGoals.usersGoals.booking = payload;
  },

  SET_USER_GOALS_BOOKINGGP(state, payload) {
    state.usersGoals.usersGoals.bookingGp = payload;
  },

  CLEAR_USER_GOALS(state) {
    state.usersGoals.usersGoals = {
      sales: null,
      booking: null,
      salesGp: null,
      bookingGp: null,
    };
    state.usersGoals.userId = null;
    state.usersGoals.goalType = {};
    state.usersGoals.goalTotal = null;
    state.usersGoals.userName = '';
  },

  SET_USER_GOAL(state, payload) {
    state.usersGoals = payload;
  },

  CLEAR_USER_GOAL(state) {
    state.usersGoals = {
      userId: null,
      year: null,
      fiscalYear: null,
      usersGoals: {
        sales: null,
        booking: null,
        salesGp: null,
        bookingGp: null,
      },
      goalType: {},
      goalTotal: null,
      periodGoalTotal: null,
      distribution: [],
      userName: ' ',
      linkedSalesIds: '',
    };
  },

  SET_LINKED_SALES_IDS(state, payload) {
    state.usersGoals.linkedSalesIds = payload;
  },

  SET_GOAL_TYPE(state, payload) {
    state.usersGoals.goalType = payload;
  },

  SET_GOAL_TOTAL(state, payload) {
    state.usersGoals.goalTotal = payload;
  },

  SET_PERIOD_GOAL_TOTAL(state, payload) {
    state.usersGoals.periodGoalTotal = payload;
  },

  SET_DISTRIBUTION(state, payload) {
    state.usersGoals.distribution = payload;
  },

  SET_STATS(state, payload) {
    state.stats = {
      activeAccountsCount: payload.activeAccountsCount,
      leadsCount: payload.leadsCount,
      contactsCount: payload.contactsCount,
      opportunitiesCount: payload.opportunitiesCount,
      openActivitiesCount: payload.openActivitiesCount,
      recentAccounts: payload.recentAccounts,
      lastOpportunity: payload.lastOpportunity,
      lastLead: payload.lastLead,
      lastContact: payload.lastContact,
    };
  },

  SET_LINKED_IDS(state, payload) {
    state.linkedIds.linkedIds = payload;
  },
};
