export default {
  getLoading: (state) => state.isLoading,

  getDocuments: (state) => {
    const documents = [...state.documents];
    return documents;
  },

  getFilesList: (state) => {
    const files = [...state.files];
    return files;
  },

  getFile: (state) => {
    const file = { ...state.file };
    return file;
  },
};
