export default {
  loading: true,
  account: {
    ticker: '',
    name: '',
    owner: {
      id: null,
      ownerId: localStorage.getItem('user_id'),
      owner: localStorage.getItem('full_name'),
      percentage: 100,
    },
    coOwner: {
      id: null,
      ownerId: '',
      owner: '',
      percentage: 0,
    },
    statusId: 103,
    typeId: null,
    description: '',
    dandBCompany: '',
    dunsNumber: '',
    naicsCode: '',
    phone: '',
    fax: '',
    website: '',
    parentId: null,
    parent: '',
    externalId: '',
    addressInformation: {
      street1: '',
      street2: '',
      street3: '',
      state: '',
      city: '',
      zip: '',
    },
    companyInformation: {
      industryId: '',
      noOfEmployeesId: '',
      annualRevenue: 0,
    },
    isActive: true,
    customFields: [],
  },
  influencerTypes: [],
  partnerAccounts: [],
  accountView: {},
  accounts: [],
};
