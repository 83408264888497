import $http from '@/plugins/axios';

export default {
  /**
   * fetchActivities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchActivities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchListActivities
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async fetchListActivities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=createdAt,desc`,
        method: 'GET',
      });
      commit('SET_LIST_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchCalendarActivities
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async fetchCalendarActivities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activities/year/${payload.year}/month/${payload.month}`,
        method: 'GET',
      });
      commit('SET_CALENDAR_ACTIVITIES', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchActivity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchActivity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: `activities/${payload}`, method: 'GET' });
      commit('SET_ACTIVITY', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createActivity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createActivity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/activities',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateActivity
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async updateActivity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activities/${payload.activityId}`,
        data: payload.activity,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createActivityAttendee
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async createActivityAttendee({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activities/${payload.activityId}/attendees`,
        data: { userId: payload.userId },
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteActivityAttendee
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async deleteActivityAttendee({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/activities/${payload.activityId}/attendees/${payload.attendeeId}`,
        method: 'DELETE',
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteActivity
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async deleteActivity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      await $http.Leads({
        url: `/activities/${payload}`,
        method: 'DELETE',
      });
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountActivities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/accounts/${payload.accountId}/activities?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContactActivities
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async fetchContactActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/contacts/${payload.contactId}/activities?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLeadActivities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.leadId}/activities?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchEmbeddedLeadActivities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchEmbeddedLeadActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.leadId}/activities?page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_EMBEDDED_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOpportunityActivities
   *
   * @param {commit} commit
   * @param {object} payload
   * @returns {Promise<JSON>}
   */
  async fetchOpportunityActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/activities?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchEmbeddedOpportunityActivities
   *
   * @param {func} commit
   * @param payload
   * @returns {Promise<*>}
   */
  async fetchEmbeddedOpportunityActivities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/activities?page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_EMBEDDED_ACTIVITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * syncActivities
   *
   * @param {func} commit
   * @returns {Promise<Boolean>}
   */
  async syncActivities({ commit }) {
    try {
      await $http.Leads({
        url: '/activities/outlook/sync',
        method: 'GET',
      });
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

};
