export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_TOKEN(state, payload) {
    localStorage.setItem('access_token', payload);
    state.token = payload;
  },

  SET_ORGANIZATION_ID(state, payload) {
    localStorage.setItem('organization_id', payload);
    state.organizationId = payload;
  },

  SET_USER_ID(state, payload) {
    localStorage.setItem('user_id', payload);
    state.userId = payload;
  },

  SET_FULL_NAME(state, payload) {
    localStorage.setItem('full_name', payload);
    state.fullName = payload;
  },

  SET_ROLE(state, payload) {
    localStorage.setItem('role', payload);
    state.role = payload;
  },

  SET_PERMISSIONS(state, payload) {
    localStorage.setItem('permissions', JSON.stringify(payload));
    state.permissions = payload;
  },

  SET_USER_PHOTO(state, payload) {
    localStorage.setItem('user_photo', payload);
    state.userPhoto = payload;
  },

  SET_CREDENTIALS_USERNAME(state, payload) {
    state.credentials.username = payload;
  },

  SET_CREDENTIALS_PASSWORD(state, payload) {
    state.credentials.password = payload;
  },

  CLEAR_CREDENTIALS(state) {
    state.credentials = {
      username: '',
      password: '',
    };
  },

  SET_SUBORDINATES(state, payload) {
    localStorage.setItem('subordinates', payload);
    state.subordinates = payload;
  },

  SET_TIMEZONE(state, payload) {
    localStorage.setItem('timezoneId', payload);
    state.timezone = payload;
  },
};
