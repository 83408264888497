export default {
  getLoading: (state) => state.isLoading,

  getDivisions: (state) => {
    const divisions = [...state.divisions];
    divisions.sort((b, a) => {
      if (a.members && b.members) {
        return a.members.length - b.members.length;
      }
      return b;
    });
    return divisions;
  },

  getDivision: (state) => {
    const division = { ...state.division };
    return division;
  },
};
