export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_DOCUMENTS(state, payload) {
    state.documents = payload;
  },

  SET_DOCUMENT(state, payload) {
    state.document = payload;
  },

  DELETE_DOCUMENT(state, payload) {
    const index = state.documents.findIndex(document.id = payload);
    state.files.splice(index, 1);
  },

  SET_FILES(state, payload) {
    state.files = payload;
  },

  SET_FILE(state, payload) {
    state.file = {
      fileName: payload.fileName,
      documentId: payload.documentId,
    };
  },

  REMOVE_FILE(state, payload) {
    state.files.splice(payload, 1);
  },

  CLEAR_FILES(state) {
    state.files = [];
  },

  CLEAR_FILE(state) {
    state.file = {
      fileName: '',
      documentId: '',
    };
  },

  SET_FILE_FILENAME(state, payload) {
    state.file.fileName = payload;
  },

  SET_FILE_DOCUMENT_ID(state, payload) {
    state.file.documentId = payload;
  },
};
