import $http from '@/plugins/axios';

export default {
  /**
   * fetchDivisions
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDivisions({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/divisions?page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_DIVISIONS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createDivision
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createDivision({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/divisions',
        data: { name: payload },
        method: 'POST',
      });
      commit('ADD_DIVISION', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateDivision
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateDivision({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/divisions/${payload.id}`,
        data: { name: payload.name },
        method: 'PUT',
      });
      commit('UPDATE_DIVISION', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteDivision
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteDivision({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/divisions/${payload}`,
        method: 'DELETE',
      });
      commit('DELETE_DIVISION', payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * addDivisionMember
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async addDivisionMember({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/divisions/${payload.divisionId}/members`,
        data: { userId: payload.userId },
        method: 'POST',
      });
      commit('SET_USER_ID', '');
      commit('ADD_DIVISION_MEMBER', {
        divisionId: payload.divisionId,
        member: response.data.data,
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteDivisionMember
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteDivisionMember({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/divisions/${payload.divisionId}/members/${payload.userId}`,
        method: 'DELETE',
      });
      commit('REMOVE_DIVISION_MEMBER', {
        divisionId: payload.divisionId,
        memberId: payload.userId,
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
