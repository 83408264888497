export default {
  getLoading: (state) => state.isLoading,

  getGrid: (state) => {
    const grid = { ...state.grid };
    return grid;
  },

  getDrilldownGrid: (state) => {
    const grid = { ...state.drilldownGrid };
    return grid;
  },

  getLookupsGrid: (state) => {
    const grid = { ...state.lookupsGrid };
    return grid;
  },

  getCustomFieldsGrid: (state) => {
    const grid = { ...state.customFieldsGrid };
    return grid;
  },

  getUsersGoalsGrid: (state) => {
    const grid = { ...state.usersGoalsGrid };
    return grid;
  },

  getUserGoalsGrid: (state) => {
    const grid = { ...state.userGoalsGrid };
    return grid;
  },

  getGoalTypesGrid: (state) => {
    const grid = { ...state.goalTypesGrid };
    return grid;
  },

  getLinkedIdsGrid: (state) => {
    const grid = { ...state.linkedIdsGrid };
    return grid;
  },
};
