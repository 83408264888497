import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    isLoading: false,
    accounts: [],
    activities: [],
    contacts: [],
    leads: [],
    opportunities: [],
    influencers: [],
    documents: [],
    users: [],
    usersGoals: [],
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getAccounts: (state) => state.accounts,
    getActivities: (state) => state.activities,
    getContacts: (state) => state.contacts,
    getLeads: (state) => state.leads,
    getOpportunities: (state) => state.opportunities,
    getInfluencers: (state) => state.influencers,
    getDocuments: (state) => state.documents,
    users: (state) => state.users,
    getUsersGoals: (state) => state.usersGoals,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_ACCOUNTS(state, payload) {
      state.accounts = payload;
    },

    SET_ACTIVITIES(state, payload) {
      state.activities = payload;
    },

    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },

    SET_LEADS(state, payload) {
      state.leads = payload;
    },

    SET_OPPORTUNITIES(state, payload) {
      state.opportunities = payload;
    },

    SET_INFLUENCERS(state, payload) {
      state.influencers = payload;
    },

    SET_DOCUMENTS(state, payload) {
      state.documents = payload;
    },

    SET_USERS(state, payload) {
      state.users = payload;
    },

    SET_USER_GOALS(state, payload) {
      state.usersGoals = payload;
    },
  },

  actions: {
    async fetchAccounts({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/accounts/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_ACCOUNTS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchActivities({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/activities/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_ACTIVITIES', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchContacts({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/contacts/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_CONTACTS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchLeads({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/leads/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_LEADS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchOpportunities({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/opportunities/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_OPPORTUNITIES', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchDocuments({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/documents/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_DOCUMENTS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchInfluencers({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/influencers/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_INFLUENCERS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUsers({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/users/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_USERS', response.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUsersGoals({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organization({
          url: `/data-grids/goal-items/export?sort=${payload.sort}${payload.filters}`,
          method: 'GET',
        });
        commit('SET_USER_GOALS', response.data.data);
        return response.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },
};
