export default {
  getLoading: (state) => state.isLoading,

  getTeams: (state) => {
    const teams = [...state.teams];
    teams.sort((b, a) => {
      if (a.members && b.members) {
        return a.members.length - b.members.length;
      }
      return b;
    });
    return teams;
  },

  getTeam: (state) => {
    const team = { ...state.team };
    return team;
  },
};
