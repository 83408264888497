export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_USERS(state, payload) {
    state.users = payload;
  },

  SET_USER(state, payload) {
    state.user = {
      userPhoto: payload.userPhoto,
      username: payload.username,
      firstName: payload.firstName,
      middleName: payload.middleName,
      lastName: payload.lastName,
      email: payload.email,
      phoneCell: payload.phoneCell,
      externalId: payload.externalId,
      roles: [payload.role[0].name],
      managerId: payload.userManagersEntity.id && payload.userManagersEntity.id !== 0
        ? payload.userManagersEntity.id : '',
      manager: payload.userManagersEntity.id !== 0
        ? `${payload.userManagersEntity.firstName} ${payload.userManagersEntity.lastName}` : '',
      isActive: payload.isActive,
    };
  },

  SET_USER_USER_PHOTO(state, payload) {
    state.user.userPhoto = payload;
  },

  SET_USER_USERNAME(state, payload) {
    state.user.username = payload;
  },

  SET_USER_PASSWORD(state, payload) {
    state.user.password = payload;
  },

  SET_USER_FIRST_NAME(state, payload) {
    state.user.firstName = payload;
  },

  SET_USER_MIDDLE_NAME(state, payload) {
    state.user.middleName = payload;
  },

  SET_USER_LAST_NAME(state, payload) {
    state.user.lastName = payload;
  },

  SET_USER_EMAIL(state, payload) {
    state.user.email = payload;
  },

  SET_USER_PHONE_CELL(state, payload) {
    state.user.phoneCell = payload;
  },

  SET_USER_EXTERNAL_ID(state, payload) {
    state.user.externalId = payload;
  },

  SET_USER_ROLE(state, payload) {
    state.user.roles = [payload];
  },

  SET_USER_MANAGER_ID(state, payload) {
    state.user.managerId = payload;
  },

  SET_USER_TEAM_ID(state, payload) {
    state.user.teamId = payload;
  },

  SET_USER_DIVISION_ID(state, payload) {
    state.user.divisionId = payload;
  },

  SET_USER_MANAGER(state, payload) {
    state.user.manager = payload;
  },

  SET_USER_TEAM(state, payload) {
    state.user.team = payload;
  },

  SET_USER_DIVISION(state, payload) {
    state.user.division = payload;
  },

  SET_USER_RESET_PASSWORD(state, payload) {
    state.user.resetPassword = payload;
  },

  SET_USER_IS_ACTIVE(state, payload) {
    state.user.isActive = payload;
  },

  SET_SALESPEOPLE(state, payload) {
    state.salespeople = payload;
  },

  CLEAR_USER(state) {
    state.user = {
      userPhoto: '',
      username: '',
      password: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneCell: '',
      externalId: '',
      roles: [],
      managerId: '',
      manager: '',
      teamId: '',
      team: '',
      divisionId: '',
      division: '',
      resetPassword: true,
      isActive: true,
    };
  },

  SET_GOAL(state, payload) {
    state.goal = {
      userId: payload.userId,
      orgId: localStorage.getItem('organization_id'),
      recurrenceIntervalId: payload.goalTypeLookupCode,
      amount: payload.goalAmount,
      quarterly: {
        q1: payload.quarterOneAmount,
        q2: payload.quarterTwoAmount,
        q3: payload.quarterThreeAmount,
        q4: payload.quarterFourAmount,
      },
    };
  },

  SET_GOAL_USER_ID(state, payload) {
    state.goal.userId = payload;
  },

  SET_GOAL_RECURRENCE_INTERVAL_ID(state, payload) {
    state.goal.recurrenceIntervalId = payload;
  },

  SET_GOAL_AMOUNT(state, payload) {
    state.goal.amount = payload;
  },

  SET_GOAL_QUARTERLY_AMOUNT_1(state, payload) {
    state.goal.quarterly.q1 = payload;
  },

  SET_GOAL_QUARTERLY_AMOUNT_2(state, payload) {
    state.goal.quarterly.q2 = payload;
  },

  SET_GOAL_QUARTERLY_AMOUNT_3(state, payload) {
    state.goal.quarterly.q3 = payload;
  },

  SET_GOAL_QUARTERLY_AMOUNT_4(state, payload) {
    state.goal.quarterly.q4 = payload;
  },

  CLEAR_GOAL(state) {
    state.goal = {
      userId: '',
      orgId: localStorage.getItem('organization_id'),
      recurrenceIntervalId: '',
      amount: '',
      quarterly: {
        q1: '',
        q2: '',
        q3: '',
        q4: '',
      },
    };
  },

  SET_PROFILE(state, payload) {
    state.profile = {
      activeAccountsCount: payload.activeAccountsCount,
      leadsCount: payload.leadsCount,
      contactsCount: payload.contactsCount,
      opportunitiesCount: payload.opportunitiesCount,
      openActivitiesCount: payload.openActivitiesCount,
      recentAccounts: payload.recentAccounts,
      lastOpportunity: payload.lastOpportunity,
      lastLead: payload.lastLead,
      lastContact: payload.lastContact,
    };
  },

  SET_SUBORDINATES(state, payload) {
    state.subordinates = payload;
  },
};
