export default {
  getLoading: (state) => state.isLoading,

  getUsers: (state) => state.users.map((user) => ({
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    fullName:
          user.middleName !== ''
            ? `${user.firstName} ${user.middleName} ${user.lastName}`
            : `${user.firstName} ${user.lastName}`,
    email: user.email,
    division: Object.keys(user.userDivisionEntity).includes('name')
      ? user.userDivisionEntity.name
      : null,
    manager: `${user.userManagersEntity.firstName} ${user.userManagersEntity.lastName}`,
    team: Object.keys(user.userTeamsEntity).includes('name') ? user.userTeamsEntity.name : null,
    role:
          user.role.length > 0 && Object.keys(user.role[0]).includes('name')
            ? user.role[0].name
            : null,
    userPhoto: user.userPhoto,
    phone: user.phoneCell,
  })),

  getUser: (state) => {
    const user = { ...state.user };
    return user;
  },

  getGoal: (state) => {
    const goal = { ...state.goal };
    return goal;
  },

  getProfile: (state) => {
    const profile = { ...state.profile };
    return profile;
  },

  getSalespeople: (state) => {
    const salespeople = [...state.salespeople];
    return salespeople;
  },

  getSubordinates: (state) => {
    const subordinates = [...state.subordinates];
    const userId = localStorage.getItem('user_id');
    // eslint-disable-next-line eqeqeq
    const index = subordinates.findIndex((subordinate) => subordinate.id == userId);
    subordinates.splice(index, 1);
    subordinates.sort((a, b) => a.fullname.localeCompare(b.fullname));
    return subordinates;
  },
};
