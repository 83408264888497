export default {
  getLoading: (state) => state.isLoading,

  getContacts: (state) => {
    const contacts = [...state.contacts];
    return contacts;
  },

  getContact: (state) => {
    const contact = { ...state.contact };
    return contact;
  },

  getDOBDate: (state) => state.date,

  getDOBMonth: (state) => state.month,
};
