export default {
  leadsVsOpportunitiesByIndustry: {},
  leadsByAccount: {},
  leadsByStage: [],
  leadsBySource: [],
  openOpportunitiesBySalesperson: {},
  opportunityDateStatus: [],
  potentialRevenueForecast: {},
  topTenAccountsByExpectedRevenue: {},
  weightedRevenueByStage: [],
  ytdSalesVsGoals: {},
  teams: [],
  divisions: [],
};
