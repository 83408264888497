import Vue from 'vue';
import Vuex from 'vuex';

// import all vuex modules
import Accounts from './modules/accounts';
import Activities from './modules/activities';
import Analytics from './modules/analytics';
import Authentication from './modules/authentication';
import Contacts from './modules/contacts';
import Divisions from './modules/divisions';
import Documents from './modules/documents';
import Export from './modules/export';
import Graphs from './modules/graphs';
import Grids from './modules/grids';
import Leads from './modules/leads';
import Lookups from './modules/lookups';
import Opportunities from './modules/opportunities';
import OrderQuoteFinancials from './modules/orderQuoteFinancials';
import Organization from './modules/organization';
import ProjectFolders from './modules/projectFolders';
import Search from './modules/search';
import Security from './modules/security';
import Settings from './modules/settings';
import Teams from './modules/teams';
import Users from './modules/users';
import Owners from './modules/owners';
import DataGrids from './modules/dataGrids';
import Exports from './modules/exports';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    Accounts,
    Activities,
    Analytics,
    Authentication,
    Contacts,
    Divisions,
    Documents,
    Export,
    Graphs,
    Grids,
    Leads,
    Lookups,
    Opportunities,
    OrderQuoteFinancials,
    Organization,
    ProjectFolders,
    Search,
    Security,
    Settings,
    Teams,
    Users,
    Owners,
    DataGrids,
    Exports,
  },
});
