import $http from '@/plugins/axios';

export default {
  /**
   * consentCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  consentCredentials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      // watch response
      window.location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${payload}&state=12345&redirect_uri=https://mxc.primedevs.io/settings`;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * azureCallback
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async azureCallback({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/azure/callback?tenant=${payload.tenant}&state=${payload.state}&admin_consent=${payload.adminConsent}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchCredentials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/credentials/${payload}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createCredentials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/credentials',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateCredentials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/credentials/${payload.credentialsId}`,
        data: payload.credentials,
        method: 'PUT',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteCredentials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/credentials/${payload}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createCustomField
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createCustomField({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/custom-fields',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateCustomField
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateCustomField({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/custom-fields/${payload.fieldId}`,
        data: payload.customField,
        method: 'PUT',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * deleteCustomField
     *
     * @param {*} { commit }
     * @param {*} payload
     * @return {*}
     */
  async deleteCustomField({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/custom-fields/${payload.id}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchCustomFields({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/custom-fields?objectName=${payload}`,
        method: 'GET',
      });
      if (payload === 'Account') {
        commit('SET_ACC_CUSTOM_FIELDS', response.data.content);
      }
      if (payload === 'Contact') {
        commit('SET_CONTACT_CUSTOM_FIELDS', response.data.content);
      }
      if (payload === 'Lead_Opportunity') {
        commit('SET_LEAD_OPP_CUSTOM_FIELDS', response.data.content);
      }
      return response;
    } catch (error) {
      // throw error.message;
      throw console.warn(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchCredentials
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchCustomField({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/custom-fields/${payload}`,
        method: 'GET',
      });
      commit('SET_CUSTOM_FIELD', response.data.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLookups
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLookups({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({
        url: '/names',
        method: 'GET',
      });
      commit('SET_LOOKUPS', response.data.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createLookup
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createLookup({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookup({
        url: '/',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
      commit('CLEAR_LOOKUP');
    }
  },

  /**
     * updateLookupStatus
     *
     * @param {*} { commit }
     * @param {*} payload
     * @return {*}
     */
  async updateLookupStatus({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookup({
        url: `/${payload.id}`,
        method: 'PUT',
        data: payload.data,
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLookups
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchCustomizations({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/settings',
        method: 'GET',
      });
      commit('SET_CUSTOMIZATIONS', response.data.data);
      commit('SET_SETTINGS', response.data?.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateCustomizations
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateCustomizations({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/settings',
        data: payload,
        method: 'PUT',
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
      // commit('CLEAR_LOOKUP');
    }
  },

  /**
   * createGoalType
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createGoalType({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/goal-types',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGoalType
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchGoalType({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-types/${payload}`,
        method: 'GET',
      });
      commit('SET_GOAL_TYPE', response.data.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateGoalType
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateGoalType({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-types/${payload.id}`,
        method: 'PUT',
        data: payload.data,
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteGoalType
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteGoalType({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-types/${payload}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createLinkedId
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createLinkedId({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/sales-ids/',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateLinkedId
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateLinkedId({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/sales-ids/${payload.id}`,
        data: payload.data,
        method: 'PUT',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * deleteLinkedId
     *
     * @param {*} { commit }
     * @param {*} payload
     * @return {*}
     */
  async deleteLinkedId({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/sales-ids/${payload}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLinkedIds
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLinkedIds({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/sales-ids?${payload}`,
        method: 'GET',
      });

      commit('SET_LINKED_IDS', response.data.content);
      return response;
    } catch (error) {
      // throw error.message;
      throw console.warn(error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLinkedId
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLinkedId({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/sales-ids/${payload}`,
        method: 'GET',
      });
      commit('SET_LINKED_ID', response.data.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchGoalPeriods({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goals/${payload}/goal-actuals`,
        method: 'GET',
      });

      commit('SET_GOAL_PERIODS', response.data?.data);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
