import axios from 'axios';

import $http from '@/plugins/axios';
import getConfig from '@/plugins/config';

const VUE_APP_AUTH_SERVER_API_BASE_URL = getConfig('VUE_APP_AUTH_SERVER_API_BASE_URL');

export default {
  /**
   * login
   *
   * Authenticate an existing user.
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async login({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Auth({
        headers: { 'Content-Type': 'multipart/form-data' },
        url: '/oauth/token',
        data: payload.payload,
        method: 'POST',
      });

      if (response.data && Object.keys(response.data).includes('access_token')) {
        // eslint-disable-next-line camelcase
        const { access_token } = response.data;
        commit('SET_TOKEN', access_token);
      }

      // fetch user credentials
      const userCredentials = await axios({
        headers: {
          Authorization: `Bearer ${response.data.access_token}`,
        },
        url: `${VUE_APP_AUTH_SERVER_API_BASE_URL}/api/user/fetch?email=${payload.username}`,
        method: 'GET',
      });

      const {
        id,
        orgId,
        role,
        userPhoto,
        firstName,
        lastName,
        userUnderManager,
        timezoneId,
      } = userCredentials.data.message;

      commit('SET_USER_ID', id);
      commit('SET_ORGANIZATION_ID', orgId);
      commit('SET_ROLE', role[0].name);
      commit('SET_PERMISSIONS', role[0].permissions);
      commit('SET_USER_PHOTO', userPhoto);
      commit('SET_FULL_NAME', `${firstName} ${lastName}`);
      commit('SET_SUBORDINATES', JSON.stringify(userUnderManager));
      commit('SET_TIMEZONE', timezoneId);

      return response;
    } catch (error) {
      localStorage.clear();
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * sendPasswordResetLink
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async sendPasswordResetLink({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Auth({
        url: `/api/user/forgotPassword?email=${payload}`,
        method: 'POST',
      });
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error.response;
    }
  },

  /**
   * resetPassword
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async resetPassword({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Auth({
        url: '/api/user/resetPassword',
        data: payload,
        method: 'POST',
      });
      return response.data;
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * logout
   *
   * @param {*} { commit }
   */
  logout({ commit }) {
    commit('SET_TOKEN', '');
    localStorage.clear();
  },

  /**
   * authorizeRedirect
   *
   * @param {*} { commit }
   * @return {*}
   */
  async authorizeRedirect({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('access_token');
      const email = localStorage.getItem('email');
      const userCredentials = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: `${VUE_APP_AUTH_SERVER_API_BASE_URL}/api/user/fetch?email=${email}`,
        method: 'GET',
      });
      commit('SET_USER_ID', userCredentials.data.message.id);
      commit('SET_ORGANIZATION_ID', userCredentials.data.message.orgId);
      commit('SET_ROLE', userCredentials.data.message.role[0].name);
      commit('SET_PERMISSIONS', userCredentials.data.message.role[0].permissions);
      commit('SET_USER_PHOTO', userCredentials.data.message.userPhoto);
      return userCredentials;
    } catch (error) {
      localStorage.clear();
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
