export default {
  getLoading: (state) => state.isLoading,

  getOrganization: (state) => {
    const organization = { ...state.organization };
    return organization;
  },

  getStats: (state) => {
    const stats = { ...state.stats };
    return stats;
  },

  getUserGoal: (state) => {
    const goal = { ...state.usersGoals };
    return goal;
  },
};
