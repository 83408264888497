import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "authentication" */
      '@/views/Authentication/Login.vue'
    ),
  }, {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "authentication" */ '@/views/Authentication/ForgotPassword.vue'
    ),
  }, {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "authentication" */ '@/views/Authentication/ResetPassword.vue'
    ),
  }, {
    path: '/organization',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "organization" */ '@/views/Organization/Index.vue'
    ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Organization',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "organization" */ '@/views/Organization/ViewOrganization.vue'
        ),
        meta: {
          requiresAuth: true,
        },
      }, {
        path: 'add-user',
        name: 'AddUser',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "organization" */ '@/views/Organization/Users/AddUser.vue'
        ),
        meta: {
          permissions: 'create_users',
        },
      }, {
        path: '/edit-user/:userId',
        name: 'EditUser',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "organization" */ '@/views/Organization/Users/EditUser.vue'
        ),
        meta: {
          permissions: 'update_users',
        },
      },
    ],
  }, {
    path: '/accounts',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "accounts" */ '@/views/Accounts/Index.vue'
    ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ListAccounts',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "accounts" */ '@/views/Accounts/ListAccounts.vue'
        ),
        meta: {
          permissions: 'read_accounts',
        },
      }, {
        path: 'add',
        name: 'AddAccount',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "accounts" */ '@/views/Accounts/AddAccount.vue'
        ),
        meta: {
          permissions: 'create_accounts',
        },
      }, {
        path: ':accountId/edit',
        name: 'EditAccount',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "accounts" */ '@/views/Accounts/EditAccount.vue'
        ),
        meta: {
          permissions: 'update_accounts',
        },
      }, {
        path: ':accountId/view',
        name: 'ViewAccount',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "accounts" */ '@/views/Accounts/ViewAccount.vue'
        ),
        meta: {
          permissions: 'read_accounts',
        },
      },
    ],
  }, {
    path: '/contacts',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "contacts" */ '@/views/Contacts/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ListContacts',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "contacts" */ '@/views/Contacts/ListContacts.vue'
        ),
        meta: {
          permissions: 'read_contacts',
        },
      }, {
        path: 'import',
        name: 'ImportContacts',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "contacts" */ '@/views/Contacts/ImportContacts.vue'
        ),
        meta: {
          permissions: 'read_contacts',
        },
      }, {
        path: 'add',
        name: 'AddContact',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "contacts" */ '@/views/Contacts/AddContact.vue'
        ),
        meta: {
          permissions: 'create_contacts',
        },
      }, {
        path: ':contactId/edit',
        name: 'EditContact',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "contacts" */ '@/views/Contacts/EditContact.vue'
        ),
        meta: {
          permissions: 'update_contacts',
        },
      }, {
        path: ':contactId/view',
        name: 'ViewContact',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "contacts" */ '@/views/Contacts/ViewContact.vue'
        ),
        meta: {
          permissions: 'read_contacts',
        },
      },
    ],
  }, {
    path: '/leads',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "leads" */ '@/views/Leads/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ListLeads',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "leads" */ '@/views/Leads/ListLeads.vue'),
        meta: {
          permissions: 'read_leads',
        },
      }, {
        path: 'add',
        name: 'AddLead',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "leads" */ '@/views/Leads/AddLead.vue'),
        meta: {
          permissions: 'create_leads',
        },
      }, {
        path: ':leadId/edit',
        name: 'EditLead',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "leads" */ '@/views/Leads/EditLead.vue'),
        meta: {
          permissions: 'update_leads',
        },
      }, {
        path: ':leadId/view',
        name: 'ViewLead',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "leads" */ '@/views/Leads/ViewLead.vue'),
        meta: {
          permissions: 'read_leads',
        },
      },
    ],
  }, {
    path: '/opportunities',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "opportunities" */ '@/views/Opportunities/Index.vue'
    ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ListOpportunities',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "opportunities" */ '@/views/Opportunities/ListOpportunities.vue'
        ),
        meta: {
          permissions: 'read_opportunities',
        },
      }, {
        path: 'add',
        name: 'AddOpportunity',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "opportunities" */ '@/views/Opportunities/AddOpportunity.vue'
        ),
        meta: {
          permissions: 'create_opportunities',
        },
      }, {
        path: ':opportunityId/edit',
        name: 'EditOpportunity',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "opportunities" */ '@/views/Opportunities/EditOpportunity.vue'
        ),
        meta: {
          permissions: 'update_opportunities',
        },
      }, {
        path: ':opportunityId/view',
        name: 'ViewOpportunity',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "opportunities" */ '@/views/Opportunities/ViewOpportunity.vue'
        ),
        meta: {
          permissions: 'read_opportunities',
        },
      },
    ],
  }, {
    path: '/activities',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "activities" */ '@/views/Activities/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'ListActivities',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "activities" */ '@/views/Activities/ListActivities.vue'
        ),
        meta: {
          permissions: 'read_activities',
        },
      }, {
        path: 'add',
        name: 'AddActivity',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "activities" */ '@/views/Activities/AddActivity.vue'
        ),
        meta: {
          permissions: 'create_activities',
        },
      }, {
        path: ':activityId/edit',
        name: 'EditActivity',
        component: () => import(/* webpackPrefetch: true */
          /* webpackChunkName: "activities" */ '@/views/Activities/EditActivity.vue'
        ),
        meta: {
          permissions: 'update_activities',
        },
      },
    ],
  }, {
    path: '/documents',
    name: 'Documents',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "documents" */ '@/views/Documents.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/analytics',
    name: 'Analytics',
    component: () => import(/* webpackPrefetch: true */ '@/views/Analytics.vue'),
    meta: {
      permissions: 'read_reports',
      requiresAuth: true,
    },
  }, {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "errors" */ '@/views/Errors/PageNotFound.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "errors" */ '@/views/Errors/Unauthorized.vue'),
  }, {
    path: '/internal-server-error',
    name: 'InternalServerError',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "errors" */ '@/views/Errors/InternalServerError.vue'
    ),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "errors" */ '@/views/Errors/Forbidden.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/redirect/:token/:email',
    name: 'Redirect',
    component: () => import(/* webpackPrefetch: true */
      /* webpackChunkName: "authentication" */ '@/views/Authentication/Redirect.vue'
    ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('access_token');

  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const hasPermissions = (permission) => (permissions === null ? null
    : permissions.find((element) => element.name === permission));

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) next({ name: 'Login' });
  else if (to.matched.some((record) => record.meta.permissions) && !hasPermissions(to.meta.permissions)) next('/unauthorized');
  else next();
});

export default router;
