import $http from '@/plugins/axios';

export default {
  /**
   * fetchOrganization
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOrganization({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({ url: '', method: 'GET' });
      commit('SET_ORGANIZATION', response.data.data);
      commit('SET_LOADING', false);
      return response.data.data;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error.response.data;
    }
  },

  /**
   * fetchStats
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchStats({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/stats',
        method: 'GET',
      });
      commit('SET_STATS', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createGoal
   *
   * @param {*} { commit }
   * @return {*}
   */
  async createGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/goal-items',
        method: 'POST',
        data: payload,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGoal
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-items/${payload}`,
        method: 'GET',
      });

      const obj = { ...response.data.data };
      const newObj = {
        userId: obj.user.id,
        year: obj.year,
        goalType: obj.goalType,
        usersGoals: {
          sales: obj.sales,
          salesGp: obj.salesGp,
          booking: obj.booking,
          bookingGp: obj.bookingGp,
        },
        userName: obj.user.fullName,
      };
      commit('SET_USER_GOAL', newObj);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateGoal
   *
   * @param {*} { commit }
   * @return {*}
   */
  async updateGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-items/${payload.id}`,
        method: 'PUT',
        data: payload.goal,
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteGoal
   *
   * @param {*} { commit }
   * @return {*}
   */
  async deleteGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/goal-items/${payload}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLinkedId
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchUserLinkedIds({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/sales-ids/user?${payload.page}&size=${payload.size}&sort=${payload.sort}${payload.filters}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

};
