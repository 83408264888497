export default {
  getLoading: (state) => state.isLoading,

  getSearchResults: (state) => {
    const searchResults = [...state.searchResults];
    return searchResults;
  },

  getAdvancedSearchParams: (state) => {
    const advancedSearchParams = { ...state.advancedSearchParams };
    return advancedSearchParams;
  },

  getSearchClause: (state) => state.searchClause,
};
