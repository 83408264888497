import $http from '@/plugins/axios';

export default {
  /**
   * fetchOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchOpportunities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchDrilldownOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDrilldownOpportunities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DRILLDOWN_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchStageOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchStageOpportunities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunitiesByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_STAGE_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOpportunity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchOpportunity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({ url: `/opportunities/${payload}`, method: 'GET' });
      commit('SET_VIEW_OPPORTUNITY', response.data.data);
      commit('SET_UPDATE_OPPORTUNITY', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLeadPermissions
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchOpportunityPermissions({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({ url: `/opportunities/${payload}/can-edit`, method: 'GET' });
      await commit('SET_READ_PERMISSIONS', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createOpportunity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createOpportunity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/opportunities',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateOpportunity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateOpportunity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}`,
        data: payload.opportunity,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateOpportunityStage
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateOpportunityStage({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/update-stage`,
        data: { stageId: payload.stageId },
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountOpportunities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/accounts/opportunities?accountId=${payload.accountId}&page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountInfluencing
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountInfluencing({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/accounts/opportunities?opportunityaccountid=${payload.accountId}&page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_INFLUENCING', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContactOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContactOpportunities({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunitiesByUserId?page=${payload.page}&size=${payload.perPage}&contactid=${payload.contactId}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_OPPORTUNITIES', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createOpportunityOwner
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createOpportunityOwner({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/owners`,
        data: { ownerId: payload.owner.id, percentage: payload.owner.percentage },
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateOpportunityOwner
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateOpportunityOwner({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/owners/${payload.id}`,
        data: { ownerId: payload.ownerId, percentage: payload.percentage },
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteOpportunityOwner
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteOpportunityOwner({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/owners/${payload.id}`,
        method: 'DELETE',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createOpportunityInfluencer
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createOpportunityInfluencer({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/partners`,
        data: payload.influencer,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * removeOpportunityInfluencer
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async removeOpportunityInfluencer({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/partners/${payload.accountId}`,
        method: 'DELETE',
      });
      commit('REMOVE_OPPORTUNITY_EDIT_INFLUENCER', payload.influencerId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * addOpportunityTag
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async addOpportunityTag({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `opportunities/${payload.opportunityId}/tags`,
        data: payload.tags,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * removeOpportunityTag
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async removeOpportunityTag({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `opportunities/${payload.opportunityId}/tags`,
        data: payload.tags,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOpportunityReasonStages
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOpportunityReasonStages({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: '/opportunities/stages', method: 'GET' });
      commit('SET_OPPORTUNITY_REASON_STAGES', response.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOpportunityReasonStages
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOpportunityStageReasons({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: `/opportunities/stages/${payload}/reasons`, method: 'GET' });
      commit('SET_OPPORTUNITY_STAGE_REASONS', response.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
