export default {
  getLoading: (state) => state.loading,

  getTimezones: (state) => {
    const data = [...state.timezones];
    return data.reverse();
  },

  getDateColorConfigs: (state) => state.dateColorConfigs,

  getAccountStatuses: (state) => {
    const data = [...state.accountStatuses];
    return data.reverse();
  },

  getAccountTypes: (state) => {
    const data = [...state.accountTypes];
    return data.sort((a, b) => a.value.localeCompare(b.value));
  },

  getIndustries: (state) => {
    const organizationId = localStorage.getItem('organization_id');
    const data = [...state.industries];
    let result = [];
    if (organizationId === '21') {
      result = data.filter((industry) => industry.code.endsWith('gop'));
    } else {
      result = data;
    }
    return result.sort((a, b) => a.value.localeCompare(b.value));
  },

  getContactTypes: (state) => {
    const data = [...state.contactTypes];
    return data.reverse();
  },

  getLeadStatuses: (state) => {
    const data = [...state.leadStatuses];
    return data;
  },

  getLeadSources: (state) => {
    const data = [...state.leadSources];
    return data.sort((a, b) => a.value.localeCompare(b.value));
  },

  getOpportunityStages: (state) => {
    const data = [...state.opportunityStages];
    return data.sort((a, b) => a.orderSequence - b.orderSequence);
  },

  getOpportunityStatus: (state) => {
    const data = [...state.opportunityStatus];
    return data.reverse();
  },

  getOpportunityClosingReasons: (state) => {
    const data = [...state.opportunityClosingReasons];
    return data;
  },

  getOpportunityTypes: (state) => {
    const organizationId = localStorage.getItem('organization_id');
    const data = [...state.opportunityTypes];
    let result = [];
    if (organizationId === '21') {
      result = data.filter((industry) => industry.code.endsWith('gop'));
    } else {
      result = data;
    }
    return result.sort((a, b) => a.value.localeCompare(b.value));
  },

  getTemperatures: (state) => {
    const data = [...state.temperatures];
    return data;
  },

  getNoOfEmployees: (state) => {
    const data = [...state.noOfEmployees];
    return data.reverse();
  },

  getProbabilities: (state) => {
    const data = [...state.probabilities];
    return data.reverse();
  },

  getActivityTypes: (state) => {
    const data = [...state.activityTypes];
    return data.reverse();
  },

  getEventTypes: (state) => {
    const data = [...state.eventTypes];
    return data.reverse();
  },

  getTaskTypes: (state) => {
    const data = [...state.taskTypes];
    return data.reverse();
  },

  getTaskPriorities: (state) => {
    const data = [...state.taskPriorities];
    return data.reverse();
  },

  getReminderAt: (state) => {
    const data = [...state.reminderAt];
    return data.reverse();
  },

  getRecurrenceIntervals: (state) => {
    const data = [...state.recurrenceIntervals];
    const result = data.filter((recurrenceInterval) => recurrenceInterval.code === 'monthly');
    return result.reverse();
  },

  getRecurringFrequencies: (state) => {
    const data = [...state.recurringFrequencies];
    return data.reverse();
  },

  getGoalTypesList: (state) => {
    const data = [...state.goalTypesList];
    return data;
  },
};
