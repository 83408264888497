import $http from '@/plugins/axios';

export default {
  /**
   * fetchLeadsVsOpportunitiesByIndustry
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadsVsOpportunitiesByIndustry({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/leads/leads-vs-opportunities-by-industry?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });

      commit('SET_LEADS_VS_OPPORTUNITIES_BY_INDUSTRY', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchLeadsByAccount
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadsByAccount({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/leads/open-leads-by-account?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_LEADS_BY_ACCOUNT', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchLeadsByStage
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadsByStage({ commit }, payload) {
    try {
      let response;
      if (payload.userid === '' && payload.teamid === '') {
        response = await $http.Leads({
          url: '/charts/leads/leads-by-stage',
          method: 'GET',
        });
      }
      response = await $http.Leads({
        url: `/charts/leads/leads-by-stage?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_LEADS_BY_STAGE', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchLeadsBySource
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadsBySource({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/leads/leads-by-source?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_LEADS_BY_SOURCE', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchOpenOpportunitiesBySalesperson
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchOpenOpportunitiesBySalesperson({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/salesmanager/opportunities/opportunities-by-salesperson?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_OPEN_OPPORTUNITIES_BY_SALESPERSON', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchOpportunityDateStatus
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchOpportunityDateStatus({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/opportunities/opportunities-date-status?userid=${payload.userid}&teamid=${payload.teamid}&bookingswitch=${payload.bookingswitch}`,
        method: 'GET',
      });
      commit('SET_OPPORTUNITY_DATE_STATUS', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchPotentialRevenueForecast
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchPotentialRevenueForecast({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/opportunities/revenue-forecast?userid=${payload.userid}&teamid=${payload.teamid}&bookingswitch=${payload.bookingswitch}`,
        method: 'GET',
      });
      commit('SET_POTENTIAL_REVENUE_FORECAST', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchTopTenAccountsByExpectedRevenue
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchTopTenAccountsByExpectedRevenue({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/accounts-by-expectedrevenue?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_TOP_TEN_ACCOUNTS_BY_EXPECTED_REVENUE', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchWeightedRevenueByStage
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchWeightedRevenueByStage({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/opportunities/weightedrevenue-by-stage?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_WEIGHTED_REVENUE_BY_STAGE', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchYtdSalesVsGoals
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchYtdSalesVsGoals({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/charts/opportunities/ytdsales-vs-goals?userid=${payload.userid}&teamid=${payload.teamid}`,
        method: 'GET',
      });
      commit('SET_YTD_SALES_VS_GOALS', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchTeams
   *
   * @param {*} { commit }
   */
  async fetchTeams({ commit }) {
    try {
      const response = await $http.Leads({
        url: '/charts/teams',
        method: 'GET',
      });
      if (response.data && response.data.data) {
        const teams = response.data.data.map((el) => el.id);
        localStorage.setItem('teams', teams);
      }
      commit('SET_TEAMS', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },

  /**
   * fetchTeams
   *
   * @param {*} { commit }
   */
  async fetchDivisions({ commit }) {
    try {
      const response = await $http.Leads({
        url: '/charts/divisions',
        method: 'GET',
      });
      if (response.data && response.data.data) {
        const divisions = response.data.data.map((el) => el.id);
        localStorage.setItem('divisions', divisions);
      }
      commit('SET_DIVISIONS', response.data.data);
    } catch (error) {
      throw error.response.data;
    }
  },
};
