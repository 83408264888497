export default {
  isLoading: false,
  users: [],
  user: {
    userPhoto: '',
    username: '',
    password: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneCell: '',
    externalId: '',
    roles: [],
    managerId: '',
    manager: '',
    teamId: '',
    team: '',
    divisionId: '',
    division: '',
    resetPassword: true,
    isActive: true,
  },

  goal: {
    userId: '',
    orgId: localStorage.getItem('organization_id'),
    recurrenceIntervalId: '',
    amount: '',
    quarterly: {
      q1: '',
      q2: '',
      q3: '',
      q4: '',
    },
  },
  profile: {
    activeAccountsCount: 0,
    leadsCount: 0,
    contactsCount: 0,
    opportunitiesCount: 0,
    openActivitiesCount: 0,
    recentAccounts: [],
    lastOpportunity: {},
    lastLead: {},
    lastContact: {},
  },

  salespeople: [],

  subordinates: [],
};
