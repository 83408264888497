import $http from '@/plugins/axios';

export default {
  /**
   * fetchOrderQuoteFinancials
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchOrderQuoteFinancials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/order-quote-financials/${payload}`,
        method: 'GET',
      });
      commit('SET_ORDER_QUOTE_FINANCIALS', response.data.data);
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOrderQuoteFinancials
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchAccountOrderQuoteFinancials({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/customer-financials/account/${payload}`,
        method: 'GET',
      });
      commit('SET_ACCOUNT_ORDER_QUOTE_FINANCIALS', response.data.data);
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchOrderQuoteFinancialsSummation
   *
   * @param {*} { commit }
   * @param {*} payload
   */
  async fetchOrderQuoteFinancialsSummation({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/order-quote-financials/sum/${payload}`,
        method: 'GET',
      });

      commit('SET_ORDER_QUOTE_FINANCIALS_SUMMATION', response.data.data);
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
