export default {
  isLoading: false,
  contacts: [],
  date: '',
  month: '',
  contact: {
    isActive: true,
    contactPhoto: null,
    ownerId: localStorage.getItem('user_id'),
    owner: localStorage.getItem('full_name'),
    title: '',
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    phone: '',
    phoneWork: '',
    address: {
      street1: '',
      street2: '',
      street3: '',
      city: '',
      state: '',
      zip: '',
    },
    dob: '',
    externalId: '',
    accountId: '',
    account: '',
    customFields: [],
  },
};
