export default {
  getLoading: (state) => state.isLoading,

  getAccounts: (state) => {
    const accounts = [...state.accounts];
    return accounts;
  },

  getContacts: (state) => {
    const contacts = [...state.contacts];
    return contacts;
  },

  getLeads: (state) => {
    const leads = [...state.leads];
    return leads;
  },

  getOpportunities: (state) => {
    const opportunities = [...state.opportunities];
    return opportunities;
  },

  getActivities: (state) => {
    const activities = [...state.activities];
    return activities;
  },

  getDocuments: (state) => {
    const documents = [...state.documents];
    return documents;
  },

};
