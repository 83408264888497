export default {
  isLoading: false,
  advancedSearchParams: {
    accounts: true,
    contacts: true,
    leads: true,
    opportunities: true,
    documents: true,
    activities: true,
    users: true,
    teams: true,
    divisions: true,
  },
  searchClause: '',
  searchResults: [],
};
