import $http from '@/plugins/axios';

export default {
  /**
   * fetchRoles
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchRoles({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Security({ url: '/getRoles', method: 'GET' });
      commit('SET_ROLES', response.data.message);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error.response;
    }
  },

  /**
   * assignRole
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async assignRole({ commit }, payload) {
    try {
      const response = await $http.Security({
        url: '/assign',
        data: payload,
        method: 'POST',
      });
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      throw error.response.data;
    }
  },
};
