export default {
  isLoading: false,
  token: localStorage.getItem('access_token') || '',
  organizationId: localStorage.getItem('organization_id') || '',
  userId: localStorage.getItem('user_id') || '',
  fullName: localStorage.getItem('full_name') || '',
  role: localStorage.getItem('role') || '',
  permissions: localStorage.getItem('permissions') || null,
  subordinates: localStorage.getItem('subordinates') || [],
  credentials: {
    username: '',
    password: '',
  },
  userPhoto: localStorage.getItem('user_photo') || null,
  timezoneId: localStorage.getItem('timezoneId') || null,
};
