import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default {
  methods: {
    /**
     * exportPDF
     *
     * export table data as PDF
     */
    exportPDF(columns, body, name) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      doc.autoTable({
        columns,
        body,
        didParseCell: (data) => {
          if (data.column.raw.field) {
            const prop = data.column.raw.field;
            const text = data.cell.raw[prop];
            if (text && text.length > 0) {
              // eslint-disable-next-line no-param-reassign
              data.cell.text = text;
            }
          }
        },
      });
      doc.save(name);
    },

    /**
     * exportXLSX
     *
     * export table data as XLSX format
     */
    exportXLSX(body, name) {
      console.log(body, name);
    },
  },
};
