export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_ACTIVITIES(state, payload) {
    state.activities = payload;
  },

  SET_CALENDAR_ACTIVITIES(state, payload) {
    state.calendarActivities = payload;
  },

  SET_EMBEDDED_ACTIVITIES(state, payload) {
    state.embeddedActivities = payload;
  },

  SET_LIST_ACTIVITIES(state, payload) {
    state.listActivities = payload;
  },

  SET_ACTIVITY(state, payload) {
    const getParent = () => {
      let result;
      if (payload.accountDto !== null) {
        result = `Account - ${payload.accountDto.name}`;
      }

      if (payload.contactDto !== null) {
        result = `Contact - ${payload.contactDto.firstName} ${payload.contactDto.lastName}`;
      }

      if (payload.opportunityDto !== null) {
        result = `Opportunity - ${payload.opportunityDto.name}`;
      }
      return result;
    };

    const parent = getParent();

    state.activity = {
      parent,
      opportunityId: payload.opportunityId,
      accountId: payload.accountId,
      contactId: payload.contactId,
      activityTypeId: payload.activityType.id,
      eventTypeId: payload.eventType ? payload.eventType.id : '',
      activityName: payload.activityName,
      description: payload.description,
      documents: payload.documents,
      owner: `${payload.owner.firstName} ${payload.owner.lastName}`,
      ownerId: payload.owner.id,
      taskPriorityId: payload.taskPriority ? payload.taskPriority.id : '',
      taskTypeId: payload.taskType ? payload.taskType.id : '',
      assignedTo: payload.assignedTo ? payload.assignedTo : '',
      isComplete: payload.isComplete,
      reminder: {
        remindAtId: payload.reminder.remindAt ? payload.reminder.remindAt.id : '',
        recurringFrequencyId: payload.reminder.recurringFrequency ? payload.reminder.recurringFrequency.id : '',
      },
      dueDate: payload.dueDate ? payload.dueDate : null,
      meetingDate: payload.meetingDate ? payload.meetingDate : null,
      meetingStartTime: payload.meetingStartTime ? payload.meetingStartTime : null,
      meetingEndTime: payload.meetingEndTime ? payload.meetingEndTime : null,
      timezoneId: payload.timezone ? payload.timezone.id : '',
      file: {
        fileName: payload.fileName ? payload.fileName : '',
        file: payload.file ? payload.file : '',
      },
      invited: payload.invited ? payload.invited : [],
      isActive: payload.isActive,
    };
  },

  SET_ACTIVITY_OPPORTUNITY_ID(state, payload) {
    state.activity.opportunityId = payload;
  },

  SET_ACTIVITY_ACCOUNT_ID(state, payload) {
    state.activity.accountId = payload;
  },

  SET_ACTIVITY_CONTACT_ID(state, payload) {
    state.activity.contactId = payload;
  },

  SET_ACTIVITY_ACTIVITY_TYPE_ID(state, payload) {
    state.activity.activityTypeId = payload;
  },

  SET_ACTIVITY_EVENT_TYPE_ID(state, payload) {
    state.activity.eventTypeId = payload;
  },

  SET_ACTIVITY_ACTIVITY_NAME(state, payload) {
    state.activity.activityName = payload;
  },

  SET_ACTIVITY_DESCRIPTION(state, payload) {
    state.activity.description = payload;
  },

  SET_ACTIVITY_OWNER(state, payload) {
    state.activity.owner = payload;
  },

  SET_ACTIVITY_OWNER_ID(state, payload) {
    state.activity.ownerId = payload;
  },

  SET_ACTIVITY_TASK_PRIORITY_ID(state, payload) {
    state.activity.taskPriorityId = payload;
  },

  SET_ACTIVITY_TASK_TYPE_ID(state, payload) {
    state.activity.taskTypeId = payload;
  },

  SET_ACTIVITY_ASSIGNED_TO(state, payload) {
    state.activity.assignedTo = payload;
  },

  SET_ACTIVITY_IS_COMPLETE(state, payload) {
    state.activity.isComplete = payload;
  },

  SET_ACTIVITY_REMINDER_REMIND_AT_ID(state, payload) {
    state.activity.reminder.remindAtId = payload;
  },

  SET_ACTIVITY_REMINDER_RECURRING_FREQUENCY_ID(state, payload) {
    state.activity.reminder.recurringFrequencyId = payload;
  },

  SET_ACTIVITY_DUE_DATE(state, payload) {
    state.activity.dueDate = payload;
  },

  SET_ACTIVITY_MEETING_DATE(state, payload) {
    state.activity.meetingDate = payload;
  },

  SET_ACTIVITY_MEETING_START_TIME(state, payload) {
    state.activity.meetingStartTime = payload;
  },

  SET_ACTIVITY_TIMEZONE_ID(state, payload) {
    state.activity.timezoneId = payload;
  },

  SET_ACTIVITY_MEETING_END_TIME(state, payload) {
    state.activity.meetingEndTime = payload;
  },

  SET_ACTIVITY_FILE_FILE_NAME(state, payload) {
    state.activity.file.fileName = payload;
  },

  SET_ACTIVITY_FILE_FILE(state, payload) {
    state.activity.file.file = payload;
  },

  ADD_ACTIVITY_INVITEE(state, payload) {
    state.activity.invited.push(payload);
  },

  REMOVE_ACTIVITY_INVITEE(state, payload) {
    const index = state.activity.invited.findIndex((el) => el.userId === payload);
    if (index === 0) state.activity.invited.unshift();
    state.activity.invited.splice(index, 1);
  },

  SET_ACTIVITY_IS_ACTIVE(state, payload) {
    state.activity.isActive = payload;
  },

  CLEAR_ACTIVITY(state) {
    state.activity = {
      parent: '',
      opportunityId: '',
      accountId: '',
      contactId: '',
      leadId: '',
      activityTypeId: '',
      eventTypeId: '',
      activityName: '',
      description: '',
      documents: [],
      owner: localStorage.getItem('full_name'),
      ownerId: localStorage.getItem('user_id'),
      taskPriorityId: '',
      taskTypeId: '',
      assignedTo: '',
      isComplete: false,
      reminder: {
        remindAtId: '',
        recurringFrequencyId: '',
      },
      dueDate: null,
      meetingDate: null,
      meetingStartTime: null,
      meetingEndTime: null,
      timezoneId: '',
      file: {
        fileName: '',
        file: '',
      },
      invited: [],
      isActive: true,
    };
  },

};
