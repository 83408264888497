export default {
  loading: false,
  timezones: [],
  contactTypes: [],
  accountStatuses: [],
  accountTypes: [],
  industries: [],
  leadStatuses: [],
  leadSources: [],
  opportunityStages: [],
  opportunityStatus: [],
  opportunityTypes: [],
  opportunityClosingReasons: [],
  temperatures: [],
  noOfEmployees: [],
  probabilities: [],
  activityTypes: [],
  eventTypes: [],
  taskTypes: [],
  taskPriorities: [],
  reminderAt: [],
  recurrenceIntervals: [],
  recurringFrequencies: [],
  dateColorConfigs: {},
  goalTypesList: [],
};
