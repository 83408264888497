export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_USER_ID(state, payload) {
    state.userId = payload;
  },

  SET_DIVISIONS(state, payload) {
    state.divisions = payload;
  },

  ADD_DIVISION(state, payload) {
    state.divisions.push(payload);
  },

  UPDATE_DIVISION(state, payload) {
    const index = state.divisions.findIndex((division) => division.id === payload.id);
    state.divisions.splice(index, 1, payload);
  },

  DELETE_DIVISION(state, payload) {
    const index = state.divisions.findIndex((division) => division.id === payload);
    if (index === 0) state.divisions.shift();
    state.divisions.splice(index, 1);
  },

  ADD_DIVISION_MEMBER(state, payload) {
    const index = state.divisions.findIndex((division) => division.id === payload.divisionId);
    if (state.divisions[index].members) {
      state.divisions[index].members.push(payload.member);
    } else {
      state.divisions[index].members = [payload.member];
    }
  },
  REMOVE_DIVISION_MEMBER(state, payload) {
    const divisionIndex = state.divisions.findIndex(
      (division) => division.id === payload.divisionId,
    );
    const memberIndex = state.divisions[divisionIndex].members.findIndex(
      (member) => member.id === payload.memberId,
    );
    state.divisions[divisionIndex].members.splice(memberIndex, 1);
  },

  SET_DIVISION(state, payload) {
    state.division.id = payload.id;
    state.division.name = payload.name;
  },

  SET_DIVISION_NAME(state, payload) {
    state.division.name = payload;
  },

  CLEAR_DIVISION(state) {
    state.division = {
      id: '',
      name: '',
    };
  },
};
