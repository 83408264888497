export default {
  getLoading: (state) => state.loading,

  getActivities: (state) => {
    const activities = [...state.activities];
    return activities;
  },

  getListActivities: (state) => {
    const listActivities = [...state.listActivities];
    return listActivities;
  },

  getCalendarActivities: (state) => {
    const calendarActivities = [...state.calendarActivities];
    return calendarActivities;
  },

  getEmbeddedActivities: (state) => {
    const embeddedActivities = [...state.embeddedActivities];
    return embeddedActivities;
  },

  getActivitiesCount: (state) => state.activitiesCount,

  getActivity: (state) => {
    const activity = { ...state.activity };
    return activity;
  },

  getActivityInvited: (state) => {
    const invited = [...state.activity.invited];
    return invited;
  },

  getNotes: (state) => {
    const activities = [...state.activities];
    return activities.filter((activity) => activity.activityType.value === 'Note');
  },
};
