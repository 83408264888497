import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import moment from 'moment';

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

extend('beforeDate', {
  validate: (value, { other }) => {
    if (!value || !other) {
      return true; // Skip validation if either value is null or undefined
    }

    const currentDate = moment(value);
    const otherDate = moment(other);

    return currentDate.isBefore(otherDate);
  },
  params: ['other'],
  message: (fieldName, placeholders) => {
    const formattedOther = moment(placeholders.other).format('DD/MM/YYYY');
    return `The ${fieldName} must be before ${formattedOther}.`;
  },
});
