import $http from '@/plugins/axios';

export default {
  /**
   * search
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async search({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/search?token=${payload}`,
        method: 'GET',
      });
      commit('SET_SEARCH_RESULTS', [response.data]);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * advancedSearch
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async advancedSearch({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/search?token=${payload.clause}&accounts=${payload.advanced.accounts}&activities=${payload.advanced.activities}&contacts=${payload.advanced.contacts}&documents=${payload.advanced.documents}&leads=${payload.advanced.leads}&opportunities=${payload.advanced.opportunities}&users=${payload.advanced.users}&teams=${payload.advanced.teams}&divisions=${payload.advanced.divisions}`,
        method: 'GET',
      });
      commit('SET_SEARCH_RESULTS', [response.data]);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchTypeAccounts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchTypeAccounts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/accountById/search?accountTypeById=${payload.accountTypeById}&token=${payload.token}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchAccounts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchAccounts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/accounts/search?token=${payload}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/contacts/search?token=${payload}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchAccountContacts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchAccountContacts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/contactsById/search?accountId=${payload.accountId}&token=${payload.token}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchLeads({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/leads/search?token=${payload}`,
        method: 'GET',
      });
      commit('SET_SEARCH_RESULTS', [response.data]);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchOpportunities
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchOpportunities({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/opportunities/search?token=${payload}`,
        method: 'GET',
      });
      commit('SET_SEARCH_RESULTS', [response.data]);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchUsers
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchUsers({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/users/search?token=${payload}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchTeams
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchTeams({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/teams/search?token=${payload}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * searchDivisions
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async searchDivisions({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Search({
        url: `/divisions/search?token=${payload}`,
        method: 'GET',
      });
      return response.data.content;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
