export default {
  getLoading: (state) => state.isLoading,

  getOrganizationId: (state) => state.organizationId,

  getRole: (state) => state.role,

  getTimezone: (state) => state.timezoneId,

  getCredentials: (state) => {
    const credentials = { ...state.credentials };
    return credentials;
  },
};
