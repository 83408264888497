export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_READ_PERMISSIONS(state, payload) {
    state.readPermissions = payload;
  },

  SET_LEADS(state, payload) {
    state.leads = payload;
  },

  SET_DRILLDOWN_LEADS(state, payload) {
    state.drilldownLeads = payload;
  },

  SET_LEAD(state, payload) {
    state.lead = {
      name: payload.name,
      description: payload.description,
      stageId: payload.stage ? payload.stage.id : '',
      temperatureId: payload.temperature ? payload.temperature.id : '',
      owner: {
        opportunityOwnerId: payload.owners && payload.owners.length > 0
          ? payload.owners[0].owner.id : '',
        id: payload.owners && payload.owners.length > 0 ? payload.owners[0].owner.id : '',
        owner: payload.owners && payload.owners.length > 0
          ? `${payload.owners[0].owner.firstName} ${payload.owners[0].owner.lastName}` : '',
        percentage: payload.owners && payload.owners.length > 0 ? payload.owners[0].percentage : '',
      },
      coOwner: {
        opportunityOwnerId: payload.owners.length > 1 ? payload.owners[1].owner.id : '',
        id: payload.owners.length > 1 ? payload.owners[1].owner.id : '',
        owner:
              payload.owners.length > 1
                ? `${payload.owners[1].owner.firstName} ${payload.owners[1].owner.lastName}`
                : '',
        percentage: payload.owners.length > 1 ? payload.owners[1].percentage : 0,
      },
      accountId: payload.accountId,
      account: payload.account && Object.keys(payload.account).includes('name') ? payload.account.name : '',
      primaryContactId: payload.primaryContactId,
      primaryContact: payload.primaryContact && Object.keys(payload.primaryContact).includes('firstName')
        ? `${payload.primaryContact.firstName} ${payload.primaryContact.lastName}` : '',
      competitorInfo: payload.competitorInfo,
      leadsSourceId: payload.leadsSource ? payload.leadsSource.id : '',
      isActive: payload.isActive,
      influencers: payload.influencers
        ? payload.influencers.map((el) => ({
          id: el.id,
          influencerTypeId: el.account.typeId,
          account: el.account.name,
          accountId: el.account.id,
          contacts: el.contacts ? el.contacts.map((item) => ({
            contact: `${item.contact.firstName} ${item.contact.lastName}`,
            contactId: item.contactId,
          })) : [],
        }))
        : [],
      projectFolderId: payload.opportunityProjectFolders
        ? payload.opportunityProjectFolders.map((el) => ({
          id: el.projectFolder.id,
          projectTitle: el.projectFolder.projectTitle,
          projectId: el.projectFolder.projectId,
        })) : [],
      customFields: payload.customFieldEntries
        ? payload.customFieldEntries.map((item) => ({
          customFieldId: item.customField.id,
          customFieldEntryId: item.id,
          label: item.customField.label,
          value: item.value,
        })).sort((a, b) => (a.customFieldId - b.customFieldId)) : [],
      estimatedPotentials: {
        probability: payload.estimatedPotentials.probability,
        estimatedRevenue: payload.estimatedPotentials.estimatedRevenue,
        estimatedGrossProfit: payload.estimatedPotentials.estimatedGrossProfit,
        estGrossProfitPercentage: payload.estimatedPotentials.estGrossProfitPercentage || 0,
        bidDueDate: payload.estimatedPotentials.bidDueDate,
        estBookingDate: payload.estimatedPotentials.estBookingDate,
        estInstallDate: payload.estimatedPotentials.estInstallDate,
        estInvoiceDate: payload.estimatedPotentials.estInvoiceDate,
      },
      reasonId: payload.reasonId,
      tags: payload.tags,
      leadTypes: payload.leadTypes.map((i) => i.lookup.id),
    };
  },

  SET_LEAD_INFLUENCERS(state, payload) {
    state.lead.influencers = payload
      ? payload.map((el) => ({
        id: el.id,
        influencerTypeId: el.accountType,
        account: el.accountName,
        accountId: el.accountId,
        contacts: el.contactNames ? el.contactNames.split(',').map((item) => ({
          contact: item.split('=')[1],
          contactId: parseInt(item.split('=')[0], 10),
        })) : [],
      }))
      : [];
  },

  SET_LEAD_INFLUENCERS_VIEW(state, payload) {
    state.leadView = {
      ...state.leadView,
      influencers: payload.map((inf) => ({
        id: inf.id,
        account: {
          id: inf.accountId,
          name: inf.accountName,
        },
        contacts: inf.contactNames ? inf.contactNames.split(',').map((item) => ({
          id: parseInt(item.split('=')[0], 10),
          name: item.split('=')[1],
        })) : [],
      })),
    };
  },

  ADD_LEAD_INFLUENCER(state, payload) {
    state.lead.influencers.push(payload);
  },

  UPDATE_LEAD_INFLUENCER(state, payload) {
    const targetInfluencer = state.lead.influencers.find(
      (influencer) => influencer.accountId === payload.accountId,
    );
    payload.contacts.forEach((contact) => {
      targetInfluencer.contacts.push(contact);
    });
  },

  REMOVE_LEAD_INFLUENCER(state, payload) {
    state.lead.influencers.splice(payload, 1);
  },

  REMOVE_LEAD_EDIT_INFLUENCER(state, payload) {
    state.lead.influencers.splice(payload, 1);
  },

  SET_LEAD_VIEW(state, payload) {
    state.leadView = {
      ...payload,
      influencers: [],
    };
  },

  SET_LEAD_OBJECT(state, payload) {
    state.lead = payload;
  },

  SET_LEAD_NAME(state, payload) {
    state.lead.name = payload;
  },

  SET_LEAD_DESCRIPTION(state, payload) {
    state.lead.description = payload;
  },

  SET_LEAD_STAGE_ID(state, payload) {
    state.lead.stageId = payload;
  },

  SET_LEAD_TEMPERATURE_ID(state, payload) {
    state.lead.temperatureId = payload;
  },

  SET_LEAD_OWNER_ID(state, payload) {
    state.lead.owner.id = payload;
  },

  SET_LEAD_OWNER(state, payload) {
    state.lead.owner.owner = payload;
  },

  SET_LEAD_CO_OWNER_ID(state, payload) {
    state.lead.coOwner.id = payload;
  },

  SET_LEAD_CO_OWNER(state, payload) {
    state.lead.coOwner.owner = payload;
  },

  SET_LEAD_OWNER_PERCENTAGE(state, payload) {
    state.lead.owner.percentage = payload;
  },

  SET_LEAD_CO_OWNER_PERCENTAGE(state, payload) {
    state.lead.coOwner.percentage = payload;
  },

  SET_LEAD_ACCOUNT_ID(state, payload) {
    state.lead.accountId = payload;
  },

  SET_LEAD_ACCOUNT(state, payload) {
    state.lead.account = payload;
  },

  SET_LEAD_PRIMARY_CONTACT_ID(state, payload) {
    state.lead.primaryContactId = payload;
  },

  SET_LEAD_PRIMARY_CONTACT(state, payload) {
    state.lead.primaryContact = payload;
  },

  SET_LEAD_COMPETITOR_INFO(state, payload) {
    state.lead.competitorInfo = payload;
  },

  SET_LEAD_LEADS_SOURCE_ID(state, payload) {
    state.lead.leadsSourceId = payload;
  },

  SET_LEAD_CLOSING_REASON(state, payload) {
    state.lead.reasonId = payload;
  },

  ADD_LEAD_PROJECT_FOLDER(state, payload) {
    state.lead.projectFolderId.push(payload);
  },

  REMOVE_LEAD_PROJECT_FOLDER(state, payload) {
    const index = state.lead.projectFolderId.findIndex((el) => el.projectId === payload);
    state.lead.projectFolderId.splice(index, 1);
  },

  SET_LEAD_PROBABILITY(state, payload) {
    state.lead.estimatedPotentials.probability = payload;
  },

  SET_LEAD_ESTIMATED_REVENUE(state, payload) {
    state.lead.estimatedPotentials.estimatedRevenue = payload;
  },

  SET_LEAD_ESTIMATED_GROSS_PROFIT(state, payload) {
    state.lead.estimatedPotentials.estimatedGrossProfit = payload;
  },

  SET_LEAD_EST_GROSS_PROFIT_PERCENTAGE(state, payload) {
    state.lead.estimatedPotentials.estGrossProfitPercentage = payload;
  },

  SET_LEAD_BID_DUE_DATE(state, payload) {
    state.lead.estimatedPotentials.bidDueDate = payload;
  },

  SET_LEAD_ESTIMATED_EST_BOOKING_DATE(state, payload) {
    state.lead.estimatedPotentials.estBookingDate = payload;
  },

  SET_LEAD_EST_INSTALL_DATE(state, payload) {
    state.lead.estimatedPotentials.estInstallDate = payload;
  },

  SET_LEAD_EST_INVOICE_DATE(state, payload) {
    state.lead.estimatedPotentials.estInvoiceDate = payload;
  },

  SET_LEAD_CUSTOM_FIELDS(state, payload) {
    state.lead.customFields = payload.sort((a, b) => (a.customFieldId - b.customFieldId));
  },

  SET_LEAD_TAGS(state, payload) {
    state.lead.tags = payload;
  },

  SET_LEAD_IS_ACTIVE(state, payload) {
    state.lead.isActive = payload;
  },

  SET_LEAD_TYPE_ID(state, payload) {
    state.lead.leadTypes = payload;
  },

  SET_OPP_STAGE_ID(state, payload) {
    state.lead.oppStageId = payload;
  },

  CLEAR_LEAD(state) {
    state.lead = {
      name: '',
      description: '',
      stageId: 143,
      temperatureId: '',
      owner: {
        id: localStorage.getItem('user_id'),
        owner: localStorage.getItem('full_name'),
        percentage: 100,
      },
      coOwner: {
        id: '',
        owner: '',
        percentage: 0,
      },
      accountId: '',
      account: '',
      primaryContactId: '',
      primaryContact: '',
      competitorInfo: '',
      leadsSourceId: '',
      projectFolderId: [],
      influencers: [],
      estimatedPotentials: {
        probability: '',
        estimatedRevenue: 0,
        estimatedGrossProfit: 0,
        estGrossProfitPercentage: 0,
        bidDueDate: null,
        estBookingDate: null,
        estInstallDate: null,
        estInvoiceDate: null,
      },
      customFields: state.lead.customFields ? state.lead.customFields.map((item) => ({ ...item, value: '' })) : [],
      tags: [],
      isActive: true,
      oppStageId: null,
      leadTypes: [],
    };
  },

  CLEAR_LEAD_VIEW(state) {
    state.leadView = {};
  },

  CLEAR_LEADS(state) {
    state.leads = [];
    state.drilldownLeads = [];
  },
};
