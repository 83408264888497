export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_TIMEZONES(state, payload) {
    state.timezones = payload;
  },

  SET_DATE_COLOR_CONFIGS(state, payload) {
    if (payload && payload.dateField) {
      const { dateField } = payload;
      state.dateColorConfigs[dateField] = payload;
    }
  },

  SET_ACCOUNT_STATUSES(state, payload) {
    state.accountStatuses = payload;
  },

  SET_ACCOUNT_TYPES(state, payload) {
    state.accountTypes = payload;
  },

  SET_CONTACT_TYPES(state, payload) {
    state.contactTypes = payload;
  },

  SET_INDUSTRIES(state, payload) {
    state.industries = payload;
  },

  SET_LEAD_STATUSES(state, payload) {
    state.leadStatuses = payload;
  },

  SET_LEAD_SOURCES(state, payload) {
    state.leadSources = payload;
  },

  SET_OPPORTUNITY_STAGES(state, payload) {
    state.opportunityStages = payload;
  },

  SET_OPPORTUNITY_STATUS(state, payload) {
    state.opportunityStatus = payload;
  },

  SET_OPPORTUNITY_CLOSING_REASONS(state, payload) {
    state.opportunityClosingReasons = payload;
  },

  SET_OPPORTUNITY_TYPES(state, payload) {
    state.opportunityTypes = payload;
  },

  SET_TEMPERATURES(state, payload) {
    state.temperatures = payload.sort((a, b) => a.orderSequence - b.orderSequence);
  },

  SET_NO_OF_EMPLOYEES(state, payload) {
    state.noOfEmployees = payload;
  },

  SET_PROBABILITIES(state, payload) {
    state.probabilities = payload;
  },

  SET_ACTIVITY_TYPES(state, payload) {
    state.activityTypes = payload;
  },

  SET_EVENT_TYPES(state, payload) {
    state.eventTypes = payload;
  },

  SET_TASK_TYPES(state, payload) {
    state.taskTypes = payload;
  },

  SET_TASK_PRIORITIES(state, payload) {
    state.taskPriorities = payload;
  },

  SET_REMINDER_AT(state, payload) {
    state.reminderAt = payload;
  },

  SET_RECURRENCE_INTERVALS(state, payload) {
    state.recurrenceIntervals = payload;
  },

  SET_RECURRING_FREQUENCIES(state, payload) {
    state.recurringFrequencies = payload;
  },

  SET_GOAL_TYPES_LIST(state, payload) {
    state.goalTypesList = payload;
  },
};
