export default {
  getLoading: (state) => state.loading,

  getPages: (state) => {
    const pages = [...state.pages];
    return pages;
  },
  getUsersActivity: (state) => {
    const userActivity = [...state.usersActivity];
    return userActivity;
  },
};
