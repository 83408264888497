export default {
  loading: false,
  leads: [],
  drilldownLeads: [],
  lead: {
    name: '',
    description: '',
    stageId: 143,
    temperatureId: '',
    owner: {
      id: localStorage.getItem('user_id'),
      owner: localStorage.getItem('full_name'),
      percentage: 100,
    },
    coOwner: {
      id: '',
      owner: '',
      percentage: 0,
    },
    accountId: '',
    account: '',
    primaryContactId: '',
    primaryContact: '',
    competitorInfo: '',
    leadsSourceId: '',
    projectFolderId: [],
    estimatedPotentials: {
      probability: '',
      estimatedRevenue: 0,
      estimatedGrossProfit: 0,
      estGrossProfitPercentage: 0,
      bidDueDate: null,
      estBookingDate: null,
      estInstallDate: null,
      estInvoiceDate: null,
    },
    influencers: [],
    customFields: [],
    reasonId: null,
    tags: [],
    isActive: true,
    oppStageId: null,
    leadTypes: [],

  },
  leadView: {},
  readPermissions: null,
};
