import $http from '@/plugins/axios';

export default {
  /**
   * getOwnerID
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async getOwnerID({ commit }, payload) {
    commit('SET_LOADING', true);

    try {
      const response = await $http.Leads({
        url: `/owner/${payload.username}`,
        method: 'GET',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateOwner
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  updateOwner({ commit }, payload) {
    commit('SET_LOADING', true);

    const {
      ownerId, newOwnerId, ids, routeName,
    } = payload;

    switch (routeName) {
      case 'ListAccounts':
        $http
          .Organization({
            url: `/accounts/owners/${ownerId}?newOwnerId=${newOwnerId}`,
            data: { accountIds: [...ids] }, // IDs of accounts to update their owner
            method: 'PUT',
          })
          .then((response) => response.data.message)
          .catch((error) => {
            throw error.response.data.message;
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
        break;
      case 'ListContacts':
        $http
          .Organization({
            url: `/contacts/owners/${newOwnerId}`,
            data: { contactIds: [...ids] }, // IDs of contacts to update their owner
            method: 'PUT',
          })
          .then((response) => response.data.message)
          .catch((error) => {
            throw error.response.data.message;
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
        break;
      case 'ListLeads':
      case 'ListOpportunities':
        $http
          .Leads({
            url: `/owner/${ownerId}?newOwnerId=${newOwnerId}`,
            data: { ids: [...ids] }, // IDs of leads / opportunities to update their owner
            method: 'PUT',
          })
          .then((response) => response.data.message)
          .catch((error) => {
            throw error.response.data.message;
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
        break;
      default:
        break;
    }
  },
};
