export default {
  data: () => ({
    userId: localStorage.getItem('user_id'),
    subordinates: localStorage.getItem('subordinates'),
    teamIds: localStorage.getItem('team_ids'),
    storedCustomFields: localStorage.getItem('custom_fields'),
  }),

  methods: {
    /**
     * hasPermissions
     *
     * Mixin to check if a user has the required permission
     *
     * @param {String} permission
     * @return {Boolean} True | False
     */
    hasPermissions(permission) {
      const permissions = JSON.parse(localStorage.getItem('permissions'));
      const selectedPermission = permissions.find((element) => element.name === permission);
      return selectedPermission !== null;
    },

    /**
     * hasRole
     *
     *  Mixin to check if a user has the required role
     *
     * @param {String} role
     * @return {Boolean} True | False
     */
    hasRole(role) {
      const assignedRole = localStorage.getItem('role');
      return assignedRole === role;
    },

    /**
     * Check if current logged in user has the role of an administrator
     *
     * @returns {Boolean} True | False
     */
    isUserAdmin() {
      const assignedRole = localStorage.getItem('role');
      const isAdmin = assignedRole.slice(-5);
      return isAdmin === 'ADMIN';
    },

    /**
     * Check if current logged in user has the role of a sales manager
     *
     * @returns {Boolean} True | False
     */
    isUserSalesManager() {
      const assignedRole = localStorage.getItem('role');
      const salesManager = assignedRole.slice(5);
      return salesManager === 'SALES_MANAGER';
    },

    /**
     * isOwner
     *
     * Mixin to check if the logged in user is the account owner
     *
     * @param {Number} ownerId
     * @param {Number} coOwnerId
     * @return {Boolean} True|False
     */
    isOwner(ownerId, coOwnerId) {
      // eslint-disable-next-line
      return ownerId == this.userId || coOwnerId == this.userId;
    },

    /**
     * isAccountOwner
     *
     * Mixin to check if the logged in user is the account owner
     *
     * @param {Number} ownerId
     * @param {Number} coOwnerId
     * @return {Boolean} True|False
     */
    isAccountOwner(ownerId, coOwnerId) {
      // eslint-disable-next-line
      return ownerId == this.userId || coOwnerId == this.userId;
    },

    /**
     * isContactOwner
     *
     * Mixin to check if the logged in user is the contact owner
     * @param {Number} ownerId
     */
    isContactOwner(ownerId) {
      // eslint-disable-next-line eqeqeq
      return this.userId == ownerId;
    },

    /**
     * isManager
     *
     * @param {Number} ownerId
     * @param {Number} coOwnerId
     */
    isManager(ownerId, coOwnerId) {
      const data = JSON.parse(this.subordinates);
      if (data) {
        const subordinates = [...JSON.parse(this.subordinates)];
        const result = subordinates.filter(
          // eslint-disable-next-line eqeqeq
          (subordinate) => subordinate == ownerId || subordinate == coOwnerId,
        );
        return result.length > 0;
      }
      return false;
    },

    isTeammate(teamIds) {
      const data = JSON.parse(this.teamIds);
      if (data && data.length > 0 && teamIds) {
        const teamIdsArray = teamIds.split(',');
        const matching = [];
        data.forEach((el) => {
          const found = teamIdsArray.find((id) => id === el);
          if (found) {
            matching.push(found);
          }
        });
        return matching.length > 0;
      }
      return false;
    },

    /**
     * isContactManager
     *
     * @param {Number} ownerId
     */
    isContactManager(ownerId) {
      const data = JSON.parse(this.subordinates);
      if (data) {
        const subordinates = [...JSON.parse(this.subordinates)];
        // eslint-disable-next-line eqeqeq
        const result = subordinates.filter((subordinate) => subordinate == ownerId);
        return result.length > 0;
      }
      return false;
    },

    getCustomFieldLabel(customField, objectName) {
      const data = JSON.parse(this.storedCustomFields);

      if (data) {
        const customFields = [...data];
        const result = customFields
          .filter((el) => (el.code === customField && el.objectName === objectName
            // ? true : (el.code === customField && el.objectName === 'Lead_Opportunity')
          ));
        return result.length > 0 ? result[0].label : '';
      }

      return '';
    },
  },
};
