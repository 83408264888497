export default {
  isLoading: false,
  credentials: {
    clientId: '',
    tenantId: '',
    secretKey: '',
  },
  customFields: {
    accountCustomFields: [],
    contactCustomFields: [],
    leadOppCustomFields: [],
    customField: {
      label: '',
      objectName: '',
      isRequired: false,
      type: '',
      lookupName: null,
    },
  },
  lookups: {
    lookups: [],
    lookup: {
      lookupName: '',
      lookupItems: [],
    },
  },
  goalTypes: {
    goalTypes: [],
    goalType: {
      type: '',
      isDefault: false,
    },
  },
  linkedIds: {
    linkedIds: [],
    linkedId: {
      salesID: '',
      description: '',
      userId: null,
      user: null,
      salesperson: '',
    },
  },
  goalPeriods: [],
  isWidgetsOpen: true,
  customizations: true,
  settings: {
    allRead: false,
    allowSalesPeopleExport: false,
    fiscalStartMonth: 0,
    organizationId: localStorage.getItem('organization_id'),
  },
};
