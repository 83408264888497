import fileDownload from 'js-file-download';
import $http from '@/plugins/axios';

export default {
  /**
   * fetchDocuments
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/documentsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createDocuments
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/documents',
        data: payload,
        method: 'POST',
      });
      commit('SET_LOADING', false);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateDocument
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async updateDocument({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/documents/${payload.documentId}`,
        data: { fileName: payload.fileName },
        method: 'PUT',
      });
      commit('SET_LOADING', false);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async deleteDocument({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      await $http.Leads({
        url: `/documents/${payload}`,
        method: 'DELETE',
      });
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async shareDocument({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/documents/${payload.documentId}/share`,
        data: payload.user,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async downloadDocument({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/documents/${payload.documentId}/download`,
        method: 'GET',
        responseType: 'blob',
      });
      commit('SET_LOADING', false);
      fileDownload(response.data, payload.fileName);
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchAccountDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/accounts/${payload.accountId}/documents?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * fetchContactDocuments
     *
     * fetch a contact's documents
     *
     * @param {Function} { commit }
     * @param {object} payload
     * @return {array} response list of documents
     */
  async fetchContactDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/contacts/${payload.contactId}/documents?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * fetch a single lead documents
     *
     * @param {Function} { commit }
     * @param {object} payload
     * @return {array} response list of documents
     */
  async fetchLeadDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.leadId}/documents?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async searchDocument({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/search?keyword=${payload.keyword}&page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.data.content);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
     * fetchOpportunityDocuments
     *
     * @param {Function} commit
     * @param {JSON} payload
     *
     * @returns JSON
     */
  async fetchOpportunityDocuments({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.opportunityId}/documents?page=${payload.page}&size=${payload.perPage}`,
        method: 'GET',
      });
      commit('SET_DOCUMENTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
