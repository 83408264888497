export default {
  loading: false,
  opportunities: [],
  drilldownOpportunities: [],
  stageOpportunities: [],
  influencing: [],
  opportunity: {
    name: '',
    description: '',
    typeId: [],
    stageId: null,
    statusId: 1,
    temperatureId: '',
    owner: {
      id: localStorage.getItem('user_id'),
      owner: localStorage.getItem('full_name'),
      percentage: 100,
    },
    coOwner: {
      id: '',
      owner: '',
      percentage: 0,
    },
    accountId: '',
    account: '',
    primaryContactId: '',
    primaryContact: '',
    competitorInfo: '',
    influencers: [],
    projectFolderId: [],
    estimatedPotentials: {
      probability: '',
      estimatedRevenue: 0,
      estimatedGrossProfit: 0,
      estGrossProfitPercentage: 0,
      bidDueDate: null,
      estBookingDate: null,
      estInstallDate: null,
      estInvoiceDate: null,
    },
    customFields: [],
    reasonId: null,
    tags: [],
    isActive: true,
    leadsSourceId: null,
  },
  reasonStages: [],
  stageReasons: [],
  opportunityView: {},
  totalOpenOpportunities: 0,
  totalOpportunitiesAmount: 0,
  totalClosedSales: 0,
  totalClosedSalesAmount: 0,
  readPermissions: null,
};
