import $http from '@/plugins/axios';

export default {
  /**
   * fetchAccounts
   *
   * **Description**: Fetch all accounts
   *
   * @param {Func} { commit }
   * @param {Object} payload page | perPage
   * @return {JSON}
   */
  async fetchAccounts({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: `/accounts?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACCOUNTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccount
   *
   * Fetch Account
   *
   * @param {Func} { commit }
   * @param {Object} payload
   * @return {JSON}
   */
  async fetchAccount({ commit }, payload) {
    try {
      const response = await $http.Organization({ url: `/accounts/${payload}`, method: 'GET' });
      commit('SET_ACCOUNT_VIEW', response.data.data);
      commit('SET_ACCOUNT', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createAccount
   *
   * Create Account
   *
   * @param {Func} { commit }
   * @param {Object} payload - account details
   * @return {Object} - saved account details
   */
  async createAccount({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: '/accounts',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateAccount
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateAccount({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: `/accounts/${payload.accountId}`,
        data: payload.account,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchInfluencerTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchInfluencerTypes({ commit }) {
    try {
      const response = await $http.Organization({ url: '/account-types', method: 'GET' });
      commit('SET_INFLUENCER_TYPES', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createAccountOwner
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createAccountOwner({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: `/accounts/${payload.accountId}/owners`,
        data: payload.owner,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateAccountOwner
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async updateAccountOwner({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: `/accounts/${payload.accountId}/owners/${payload.id}`,
        data: { ownerId: payload.ownerId, percentage: payload.percentage },
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteAccountOwner
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async deleteAccountOwner({ commit }, payload) {
    try {
      const response = await $http.Organization({
        url: `/accounts/${payload.accountId}/owners/${payload.id}`,
        method: 'DELETE',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContactAccounts
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContactAccounts({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: `/accounts?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}&contactid=${payload.contactId}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_ACCOUNTS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
