export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_GRID(state, payload) {
    state.grid = payload;
  },

  SET_DRILLDOWN_GRID(state, payload) {
    state.drilldownGrid = payload;
  },

  SET_LOOKUPS_GRID(state, payload) {
    state.lookupsGrid = payload;
  },

  SET_CUSTOM_FIELDS_GRID(state, payload) {
    state.customFieldsGrid = payload;
  },

  SET_USERS_GOALS_GRID(state, payload) {
    state.usersGoalsGrid = payload;
  },

  SET_USER_GOALS_GRID(state, payload) {
    state.userGoalsGrid = payload;
  },

  SET_GOAL_TYPES_GRID(state, payload) {
    state.goalTypesGrid = payload;
  },

  SET_LINKED_IDS_GRID(state, payload) {
    state.linkedIdsGrid = payload;
  },

};
