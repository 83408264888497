export default {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_CONTACTS(state, payload) {
    state.contacts = payload;
  },

  SET_CONTACT(state, payload) {
    state.contact = {
      ...payload,
      customFields: payload.customFieldEntries.map((item) => ({
        customFieldId: item.customField.id,
        customFieldEntryId: item.id,
        label: item.customField.label,
        value: item.value,
      })).sort((a, b) => (a.customFieldId - b.customFieldId)),
    };
    state.contact.contactPhoto = payload.photo;
    state.contact.owner = payload.contactOwner
      ? `${payload.contactOwner.firstName} ${payload.contactOwner.lastName}` : 'Unassigned';
    state.month = payload.dob ? payload.dob.split('-')[0] : '';
    state.date = payload.dob ? payload.dob.split('-')[1] : '';
  },

  SET_CONTACT_OBJECT(state, payload) {
    state.contact = payload;
  },

  SET_CONTACT_CUSTOM_FIELDS(state, payload) {
    state.contact.customFields = payload.sort((a, b) => (a.customFieldId - b.customFieldId));
  },

  SET_CONTACT_CONTACT_PHOTO(state, payload) {
    state.contact.contactPhoto = payload;
  },

  SET_CONTACT_OWNER_ID(state, payload) {
    state.contact.ownerId = payload;
  },

  SET_CONTACT_OWNER(state, payload) {
    state.contact.owner = payload;
  },

  SET_CONTACT_TITLE(state, payload) {
    state.contact.title = payload;
  },

  SET_CONTACT_PREFIX(state, payload) {
    state.contact.prefix = payload;
  },

  SET_CONTACT_FIRST_NAME(state, payload) {
    state.contact.firstName = payload;
  },

  SET_CONTACT_MIDDLE_NAME(state, payload) {
    state.contact.middleName = payload;
  },

  SET_CONTACT_LAST_NAME(state, payload) {
    state.contact.lastName = payload;
  },

  SET_CONTACT_EMAIL_ADDRESS(state, payload) {
    state.contact.emailAddress = payload;
  },

  SET_CONTACT_PHONE(state, payload) {
    state.contact.phone = payload;
  },

  SET_CONTACT_WORK_PHONE(state, payload) {
    state.contact.phoneWork = payload;
  },

  SET_CONTACT_ADDRESS_STREET_1(state, payload) {
    state.contact.address.street1 = payload;
  },

  SET_CONTACT_ADDRESS_STREET_2(state, payload) {
    state.contact.address.street2 = payload;
  },

  SET_CONTACT_ADDRESS_STREET_3(state, payload) {
    state.contact.address.street3 = payload;
  },

  SET_CONTACT_ADDRESS_CITY(state, payload) {
    state.contact.address.city = payload;
  },

  SET_CONTACT_ADDRESS_STATE(state, payload) {
    state.contact.address.state = payload;
  },

  SET_CONTACT_ADDRESS_ZIP(state, payload) {
    state.contact.address.zip = payload;
  },

  SET_CONTACT_DOB_MONTH(state, payload) {
    state.month = payload;
  },

  SET_CONTACT_DOB_DATE(state, payload) {
    state.date = payload;
  },

  SET_CONTACT_EXTERNAL_ID(state, payload) {
    state.contact.externalId = payload;
  },

  SET_CONTACT_ACCOUNT_ID(state, payload) {
    state.contact.accountId = payload;
  },

  SET_CONTACT_ACCOUNT(state, payload) {
    state.contact.account = payload;
  },

  SET_CONTACT_IS_ACTIVE(state, payload) {
    state.contact.isActive = payload;
  },

  DELETE_CONTACT(state, payload) {
    const index = state.contacts.findIndex((element) => element.id === payload);
    if (index === 0) state.contacts.unshift();
    state.contacts.splice(index, 1);
  },

  CLEAR_CONTACT(state) {
    state.contact = {
      isActive: true,
      contactPhoto: null,
      ownerId: localStorage.getItem('user_id'),
      owner: localStorage.getItem('full_name'),
      title: '',
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      emailAddress: '',
      phone: '',
      phoneWork: '',
      address: {
        street1: '',
        street2: '',
        street3: '',
        city: '',
        state: '',
        zip: '',
      },
      dob: '',
      locationId: '',
      externalId: '',
      accountId: '',
      account: '',
      customFields: state.contact.customFields ? state.contact.customFields.map((item) => ({ ...item, value: '' })) : [],
    };
    state.date = '';
    state.month = '';
  },
};
