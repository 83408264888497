export default {
  getLoading: (state) => state.loading,

  getAccounts: (state) => {
    const accounts = [...state.accounts];
    return accounts;
  },

  getAccount: (state) => {
    const account = { ...state.account };
    return account;
  },

  getAccountView: (state) => {
    const accountView = { ...state.accountView };
    return accountView;
  },

  getInfluencerTypes: (state) => {
    const influencerTypes = [...state.influencerTypes];
    return influencerTypes;
  },

  getPartnerAccounts: (state) => {
    const partnerAccounts = [...state.partnerAccounts];
    return partnerAccounts;
  },
};
