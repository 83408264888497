import $http from '@/plugins/axios';

export default {
  async resetAllGridFilters({ commit }) {
    commit('SET_LOADING', true);
    try {
      await $http.Grids({
        url: '/grids/reset-filters',
        method: 'POST',
      });
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  /**
   * createGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: '/grids',
        data: payload,
        method: 'POST',
      });

      commit('SET_GRID', response.data.data[0]);

      if (response.data.data[0].customizations.isWidgetsOpen !== undefined) {
        commit('Settings/SET_WIDGETS_OPEN', response.data.data[0].customizations.isWidgetsOpen, { root: true });
      }

      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.name}`,
        data: payload,
        method: 'PUT',
      });

      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      if (response.data.data.length <= 0) {
        return false;
      }
      commit('SET_GRID', response.data.data[0]);
      if (response.data.data[0].customizations.isWidgetsOpen !== undefined) {
        commit('Settings/SET_WIDGETS_OPEN', response.data.data[0].customizations.isWidgetsOpen, { root: true });
      }
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createDrilldownGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createDrilldownGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: '/grids',
        data: payload,
        method: 'POST',
      });
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateDrilldownGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateDrilldownGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.name}`,
        data: payload,
        method: 'PUT',
      });
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchDrilldownGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchDrilldownGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_DRILLDOWN_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLookupsGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchLookupsGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_LOOKUPS_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchCustomFieldsGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_CUSTOM_FIELDS_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchUsersGoalsGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchUsersGoalsGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_USERS_GOALS_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchUserGoalsGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchUserGoalsGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_USER_GOALS_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGoalTypesGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchGoalTypesGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_GOAL_TYPES_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLinkedIdsGrid
   *
   * @param {*} { commit }
   * @param {*} payload
   * @returns {Promise<*>}
   */
  async fetchLinkedIdsGrid({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Grids({
        url: `/grids?gridname=${payload.gridName}&organizationid=${payload.organizationId}`,
        method: 'GET',
      });
      commit('SET_LINKED_IDS_GRID', response.data.data[0]);
      return response.data.data[0];
    } catch (error) {
      throw error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },

};
