<template>
  <div id="app">
    <router-view :key="$route.name + ($route.params.accountId || $route.fullPath || '')" />
  </div>
</template>

<script>
export default {
  name: 'App',

  watch: {
    $route: {
      handler(to, from) {
        this.$store.commit('DataGrids/CLEAR_ACCOUNTS');
        this.$store.commit('DataGrids/CLEAR_ACTIVITIES');
        this.$store.commit('DataGrids/CLEAR_ACTIVITIES_LIST');
        this.$store.commit('DataGrids/CLEAR_CONTACTS');
        this.$store.commit('DataGrids/CLEAR_LEADS');
        this.$store.commit('DataGrids/CLEAR_LEADS_DRILLDOWN');
        this.$store.commit('DataGrids/CLEAR_OPPORTUNITIES');
        this.$store.commit('DataGrids/CLEAR_OPPORTUNITIES_DRILLDOWN');
        this.$store.commit('DataGrids/CLEAR_DOCUMENTS');
        this.$store.commit('DataGrids/CLEAR_USERS');
        if (from.name !== to.name) {
          this.$store.commit('Grids/SET_GRID', {});
          this.$store.commit('Grids/SET_DRILLDOWN_GRID', {});
        }
        if (localStorage.getItem('access_token')) {
          this.$store.dispatch('DataGrids/fetchCustomColumns');
        }
      },

      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app.scss';

#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.grip-vertical {
  background-image: url('./assets/images/grip-vertical.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 20px;
  margin-left: 12px;
}

.dg-columns{
  a.dropdown-item {
    padding-right: 0;
  }

  .field.has-addons {
    justify-content: space-between;
  }
}

</style>
