import $http from '@/plugins/axios';

export default {
  /**
   * fetchUsers
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchUsers({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await $http.Users({
        url: `/user/fetchByOrgId?orgId=${organizationId}&page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      if (response.data && response.data.message) { commit('SET_USERS', response.data.message.content); }
      return response.data.message;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchSubordinates
   *
   * @param {*} { commit }
   */
  async fetchSubordinates({ commit }) {
    commit('SET_LOADING', true);
    try {
      const organizationId = localStorage.getItem('organization_id');
      const userId = localStorage.getItem('user_id');
      const response = await $http.Users({
        url: `/user/fetchUsersUnderManager?userid=${userId}&organizationid=${organizationId}`,
        method: 'GET',
      });
      commit('SET_SUBORDINATES', response.data.message);
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchSalespeople
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchSalespeople({ commit }) {
    commit('SET_LOADING', true);
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await $http.Users({
        url: `/user/fetchByOrgId?orgId=${organizationId}&rolename=ROLE_SALES_PERSON`,
        method: 'GET',
      });
      commit('SET_SALESPEOPLE', response.data.message.content);
      return response.data.message;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchUser
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchUser({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({ url: `/user/fetchById?id=${payload}`, method: 'GET' });
      commit('SET_USER', response.data.message[0]);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createUser
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createUser({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({
        url: '/user/signup',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateUser
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateUser({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({
        url: '/user/update',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createGoal
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({
        url: 'goals/create',
        data: payload,
        method: 'POST',
      });
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchGoal
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchGoal({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Users({
        url: `goals/fetchById?userId=${payload}`,
        method: 'GET',
      });

      if (response.data.message.length > 0) {
        const index = response.data.message.length - 1;
        commit('SET_GOAL', response.data.message[index]);
      }
      return response;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchProfile
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchProfile({ commit }) {
    commit('SET_LOADING', true);
    try {
      const userId = localStorage.getItem('user_id');
      const response = await $http.Leads({
        url: `/user/${userId}/stats`,
        method: 'GET',
      });
      commit('SET_PROFILE', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
