export default {
  getLoading: (state) => state.loading,

  getReadPermissions: (state) => state.readPermissions?.canEdit || null,

  getLeads: (state) => {
    const leads = [...state.leads];
    return leads;
  },

  getDrilldownLeads: (state) => {
    const drilldownLeads = [...state.drilldownLeads];
    return drilldownLeads;
  },

  getLead: (state) => {
    const lead = { ...state.lead };
    return lead;
  },

  getLeadInfluencers: (state) => {
    const influencers = [...state.lead.influencers];
    return influencers;
  },

  getLeadInfluencersView: (state) => {
    const { influencers } = state.leadView;
    return influencers;
  },

  getLeadView: (state) => {
    const leadView = { ...state.leadView };
    return leadView;
  },

  getOpportunityProjectFolders: (state) => state.lead.projectFolderId,
};
