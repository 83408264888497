export default {
  getLeadsVsOpportunitiesByIndustry: (state) => {
    const leadsVsOpportunitiesByIndustry = { ...state.leadsVsOpportunitiesByIndustry };
    return leadsVsOpportunitiesByIndustry;
  },

  getLeadsByAccount: (state) => {
    const leadsByAccount = { ...state.leadsByAccount };
    return leadsByAccount;
  },

  getLeadsByStage: (state) => {
    const leadsByStage = [...state.leadsByStage];
    return leadsByStage;
  },

  getLeadsBySource: (state) => {
    const leadsBySource = [...state.leadsBySource];
    return leadsBySource;
  },

  getOpenOpportunitiesBySalesperson: (state) => {
    const openOpportunitiesBySalesperson = { ...state.openOpportunitiesBySalesperson };
    return openOpportunitiesBySalesperson;
  },

  getOpportunityDateStatus: (state) => {
    const opportunityDateStatus = [...state.opportunityDateStatus];
    return opportunityDateStatus;
  },

  getPotentialRevenueForecast: (state) => {
    const potentialRevenueForecast = { ...state.potentialRevenueForecast };
    return potentialRevenueForecast;
  },

  getTopTenAccountsByExpectedRevenue: (state) => {
    const topTenAccountsByExpectedRevenue = { ...state.topTenAccountsByExpectedRevenue };
    return topTenAccountsByExpectedRevenue;
  },

  getWeightedRevenueByStage: (state) => {
    const weightedRevenueByStage = [...state.weightedRevenueByStage];
    return weightedRevenueByStage;
  },

  getYtdSalesVsGoals: (state) => {
    const ytdSalesVsGoals = { ...state.ytdSalesVsGoals };
    return ytdSalesVsGoals;
  },

  getTeams: (state) => {
    const teams = [...state.teams];
    teams.sort((a, b) => a.name.localeCompare(b.name));
    return teams;
  },

  getDivisions: (state) => {
    const divisions = [...state.divisions];
    divisions.sort((a, b) => a.name.localeCompare(b.name));
    return divisions;
  },
};
