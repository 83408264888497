export default {
  SET_LOADING(state, payLoad) {
    state.isLoading = payLoad;
  },

  SET_PROJECT_FOLDERS(state, payload) {
    state.projectFolders = payload;
  },

  CLEAR_PROJECT_FOLDERS(state) {
    state.projectFolders = [];
  },
};
