import $http from '@/plugins/axios';

export default {
  /**
   * fetchTimezones
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchTimezones({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/outlook_timezone', method: 'GET' });
      commit('SET_TIMEZONES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchDateColorConfig
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDateColorConfig({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/dateGrids?dateField=${payload.dateField}`,
        method: 'GET',
      });
      commit('SET_DATE_COLOR_CONFIGS', response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * addDateColorConfig
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async addDateColorConfig({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/dateGrids',
        data: payload,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateDateColorConfig
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateDateColorConfig({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: '/dateGrids',
        data: payload,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountStatuses
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchAccountStatuses({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/account_status', method: 'GET' });
      commit('SET_ACCOUNT_STATUSES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchAccountTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchAccountTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/account_type', method: 'GET' });
      commit('SET_ACCOUNT_TYPES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchIndustries
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchIndustries({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/industry', method: 'GET' });
      commit('SET_INDUSTRIES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchContactTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchContactTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/contact_type', method: 'GET' });
      commit('SET_CONTACT_TYPES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchLeadStatuses
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchLeadStatuses({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/lead_stage', method: 'GET' });
      commit('SET_LEAD_STATUSES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchLeadSources
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchLeadSources({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/lead_source', method: 'GET' });
      commit('SET_LEAD_SOURCES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchOpportunityStages
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOpportunityStages({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/opportunity_stage', method: 'GET' });
      commit('SET_OPPORTUNITY_STAGES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchOpportunityStatus
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOpportunityStatus({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/opportunity_status', method: 'GET' });
      commit('SET_OPPORTUNITY_STATUS', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchOpportunityClosingReasons
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchOpportunityClosingReasons({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: '/opportunities/stages/reasons', method: 'GET' });
      commit('SET_OPPORTUNITY_CLOSING_REASONS', response.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchOpportunityTypes
   *
   * @param {*} { commit }
   */
  async fetchOpportunityTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/opportunity_type', method: 'GET' });
      commit('SET_OPPORTUNITY_TYPES', response.data.data);
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchTemperatures
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchTemperatures({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/temperature', method: 'GET' });
      commit('SET_TEMPERATURES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchNoOfEmployees
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchNoOfEmployees({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/employee', method: 'GET' });
      commit('SET_NO_OF_EMPLOYEES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchProbabilities
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchProbabilities({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/probability', method: 'GET' });
      commit('SET_PROBABILITIES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchActivityTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchActivityTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/activity_type', method: 'GET' });
      commit('SET_ACTIVITY_TYPES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchEventTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchEventTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/event_type', method: 'GET' });
      commit('SET_EVENT_TYPES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchTaskTypes
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchTaskTypes({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/task_type', method: 'GET' });
      commit('SET_TASK_TYPES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchTaskPriorities
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchTaskPriorities({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/priority', method: 'GET' });
      commit('SET_TASK_PRIORITIES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchReminderAt
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchReminderAt({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/reminder_at', method: 'GET' });
      commit('SET_REMINDER_AT', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchRecurrenceIntervals
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchRecurrenceIntervals({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/goal', method: 'GET' });
      commit('SET_RECURRENCE_INTERVALS', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchRecurringFrequencies
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchRecurringFrequencies({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Lookups({ url: '/recurring_frequency', method: 'GET' });
      commit('SET_RECURRING_FREQUENCIES', response.data.data);
      commit('SET_LOADING', false);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },

  /**
   * fetchGoalTypesList
   *
   * @param {*} { commit }
   * @return {*}
   */
  async fetchGoalTypesList({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({
        url: '/goal-types',
        method: 'GET',
      });
      commit('SET_GOAL_TYPES_LIST', response.data.data.content);
      return response.data;
    } catch (error) {
      commit('SET_LOADING', false);
      return error.response.data;
    }
  },
};
