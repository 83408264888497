export default {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_READ_PERMISSIONS(state, payload) {
    state.readPermissions = payload;
  },

  SET_OPPORTUNITIES(state, payload) {
    state.opportunities = payload;
  },

  SET_DRILLDOWN_OPPORTUNITIES(state, payload) {
    state.drilldownOpportunities = payload;
  },

  SET_STAGE_OPPORTUNITIES(state, payload) {
    state.stageOpportunities = payload;
  },

  SET_INFLUENCING(state, payload) {
    state.influencing = payload;
  },

  SET_OPPORTUNITY(state, payload) {
    state.opportunity = {
      name: payload.name,
      description: payload.description,
      typeId: [...payload.opportunityTypes].map((i) => i.lookup.id),
      stageId: payload.stage ? payload.stage.orderSequence : null,
      statusId: 1,
      temperatureId: payload.temperatureId,
      owner: {
        opportunityOwnerId: payload.owners && payload.owners.length > 0
          ? payload.owners[0].owner.id : '',
        id: payload.owners && payload.owners.length > 0 ? payload.owners[0].owner.id : '',
        owner: payload.owners && payload.owners.length > 0
          ? `${payload.owners[0].owner.firstName} ${payload.owners[0].owner.lastName}` : '',
        percentage: payload.owners && payload.owners.length > 0 ? payload.owners[0].percentage : '',
      },
      coOwner: {
        opportunityOwnerId: payload.owners.length > 1 ? payload.owners[1].owner.id : '',
        id: payload.owners.length > 1 ? payload.owners[1].owner.id : '',
        owner:
            payload.owners.length > 1
              ? `${payload.owners[1].owner.firstName} ${payload.owners[1].owner.lastName}`
              : '',
        percentage: payload.owners.length > 1 ? payload.owners[1].percentage : 0,
      },
      accountId: payload.accountId,
      account: Object.keys(payload.account).includes('name') ? payload.account.name : '',
      primaryContactId: payload.primaryContactId,
      primaryContact: Object.keys(payload.primaryContact).includes('firstName')
        ? `${payload.primaryContact.firstName} ${payload.primaryContact.lastName}`
        : '',
      competitorInfo: payload.competitorInfo,
      influencers: payload.influencers
        ? payload.influencers.map((el) => ({
          id: el.id,
          influencerTypeId: el.account.typeId,
          account: el.account.name,
          accountId: el.account.id,
          contacts: el.contacts ? el.contacts.map((item) => ({
            contact: `${item.contact.firstName} ${item.contact.lastName}`,
            contactId: item.contactId,
          })) : [],
        }))
        : [],
      projectFolderId: payload.opportunityProjectFolders.map((el) => ({
        id: el.projectFolder.id,
        projectTitle: el.projectFolder.projectTitle,
        projectId: el.projectFolder.projectId,
      })),
      estimatedPotentials: {
        probability: payload.estimatedPotentials.probability,
        estimatedRevenue: payload.estimatedPotentials.estimatedRevenue,
        estimatedGrossProfit: payload.estimatedPotentials.estimatedGrossProfit,
        estGrossProfitPercentage: payload.estimatedPotentials.estGrossProfitPercentage,
        bidDueDate: payload.estimatedPotentials.bidDueDate,
        estBookingDate: payload.estimatedPotentials.estBookingDate,
        estInstallDate: payload.estimatedPotentials.estInstallDate,
        estInvoiceDate: payload.estimatedPotentials.estInvoiceDate,
      },
      reasonId: payload.reasonId,
      customFields: payload.customFieldEntries.map((item) => ({
        customFieldId: item.customField.id,
        customFieldEntryId: item.id,
        label: item.customField.label,
        value: item.value,
      })).sort((a, b) => (a.customFieldId - b.customFieldId)),
      tags: payload.tags,
      leadsSourceId: payload.leadsSource ? payload.leadsSource.id : null,
    };
  },

  SET_VIEW_OPPORTUNITY(state, payload) {
    state.opportunityView = {
      id: payload.id,
      name: payload.name,
      description: payload.description,
      accountId: payload.accountId,
      accountName: payload.accountName,
      stageId: payload.stageLookupId,
      stage: payload.stage,
      stageOrderSequence: payload.stageOrderSequence ? Number(payload.stageOrderSequence) : null,
      industry: payload.industry,
      reason: payload.reason,
      reasonId: payload.reasonLookupId,
      tags: payload.tags ? payload.tags.split(',') : null,
      owner: {
        id: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[1] : '',
        owner: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[2] : '',
        photo: payload.displayOwners && payload.displayOwners.split(',')[0] && payload.displayOwners.split(',')[0].split('=')[3] !== 'avatar'
          ? payload.displayOwners.split(',')[0].split('=')[3] : null,
        percentage: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[4] : '',
      },
      coOwner: {
        id: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[1] : '',
        owner: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[2] : '',
        photo: payload.displayOwners && payload.displayOwners.split(',')[1] && payload.displayOwners.split(',')[1].split('=')[3] !== 'avatar'
          ? payload.displayOwners.split(',')[1].split('=')[3] : null,
        percentage: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[4] : '',
      },
      estimatedPotentials: {
        estimatedRevenue: payload.estimatedRevenue,
        estimatedGrossProfit: payload.expectedGp,
        bidDueDate: payload.bidDueDate,
        estBookingDate: payload.estBookingDate,
        estInstallDate: payload.estInstallDate,
        estInvoiceDate: payload.estInvoiceDate,
        probability: payload.probability,
        estGrossProfitPercentage: payload.estimatedGrossProfitPercent,
      },
      primaryContactId: payload.primaryContactId,
      contactName: payload.contactName,
      contactEmail: payload.contactEmail,
      contactPhone: payload.contactPhone,
      competitorInfo: payload.competitorInfo,
      dateClosed: payload.dateClosed,
      statusId: payload.statusId,
      projectFolderId: payload.projectFolders ? payload.projectFolders.split(',') : null,
      isActive: true,
      customFields: payload.customFields ? payload.customFields.split('=').map((customField) => ({
        value: customField.split('-')[1],
        label: customField.split('-')[3],
      })) : null,
      opportunityTypes: payload.types,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
      leadsSource: payload.source,
      teamIds: payload.teamIds || null,
    };
  },

  SET_UPDATE_OPPORTUNITY(state, payload) {
    state.opportunity = {
      name: payload.name,
      description: payload.description,
      temperatureId: payload.tempLookupId,
      accountId: payload.accountId,
      account: payload.accountName,
      stageId: payload.stageOrderSequence,
      reasonId: parseInt(payload.reasonLookupId, 10),
      influencers: [],
      owner: {
        id: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[1] : '',
        opportunityOwnerId: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[1] : '',
        owner: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? payload.displayOwners.split(',')[0].split('=')[2] : '',
        percentage: payload.displayOwners && payload.displayOwners.split(',')[0]
          ? Number(payload.displayOwners.split(',')[0].split('=')[4]) : '',
      },
      coOwner: {
        id: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[1] : '',
        opportunityOwnerId: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[1] : '',
        owner: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? payload.displayOwners.split(',')[1].split('=')[2] : '',
        percentage: payload.displayOwners && payload.displayOwners.split(',')[1]
          ? Number(payload.displayOwners.split(',')[1].split('=')[4]) : 0,
      },
      tags: payload.tags ? payload.tags.split(',') : null,
      estimatedPotentials: {
        estimatedRevenue: payload.estimatedRevenue ? Number(payload.estimatedRevenue) : '',
        estimatedGrossProfit: payload.expectedGp ? Number(payload.expectedGp) : '',
        bidDueDate: payload.bidDueDate,
        estBookingDate: payload.estBookingDate,
        estInstallDate: payload.estInstallDate,
        estInvoiceDate: payload.estInvoiceDate,
        probability: payload.probability ? Number(payload.probability) : '',
        estGrossProfitPercentage: payload.estimatedGrossProfitPercent,
      },
      primaryContactId: payload.primaryContactId,
      primaryContact: payload.contactName,
      competitorInfo: payload.competitorInfo,
      // dateClosed: payload.dateClosed,
      statusId: payload.statusId,
      projectFolderId: payload.projectFolders ? payload.projectFolders.split(',').map((projectFolder) => ({
        id: projectFolder.split('-')[0],
        projectTitle: projectFolder.split('-')[1],
        projectId: projectFolder.split('-')[2],
      })) : null,
      isActive: true,
      customFields: payload.customFields ? payload.customFields.split('=').map((customField) => ({
        customFieldId: parseInt(customField.split('-')[2], 10),
        value: customField.split('-')[1],
        customFieldEntryId: parseInt(customField.split('-')[0], 10),
        label: customField.split('-')[3],
      })) : [],
      typeId: payload.typeIds ? payload.typeIds.split(',').map((i) => parseInt(i, 10)) : [],
      leadsSourceId: payload.sourceLookupId,
    };
  },

  SET_OPPORTUNITY_INFLUENCERS(state, payload) {
    state.opportunity.influencers = payload
      ? payload.map((el) => ({
        id: el.id,
        influencerTypeId: el.accountType,
        account: el.accountName,
        accountId: el.accountId,
        contacts: el.contactNames ? el.contactNames.split(',').map((item) => ({
          contact: item.split('=')[1],
          contactId: parseInt(item.split('=')[0], 10),
        })) : [],
      }))
      : [];
  },

  SET_OPPORTUNITY_INFLUENCERS_VIEW(state, payload) {
    state.opportunityView = {
      ...state.opportunityView,
      influencers: payload.map((inf) => ({
        id: inf.id,
        account: {
          id: inf.accountId,
          name: inf.accountName,
        },
        contacts: inf.contactNames ? inf.contactNames.split(',').map((item) => ({
          id: parseInt(item.split('=')[0], 10),
          name: item.split('=')[1],
        })) : [],
      })),
    };
  },

  ADD_OPPORTUNITY_INFLUENCER(state, payload) {
    state.opportunity.influencers.push(payload);
  },

  UPDATE_OPPORTUNITY_INFLUENCER(state, payload) {
    const targetInfluencer = state.opportunity.influencers.find(
      (influencer) => influencer.accountId === payload.accountId,
    );
    payload.contacts.forEach((contact) => {
      targetInfluencer.contacts.push(contact);
    });
  },

  REMOVE_OPPORTUNITY_INFLUENCER(state, payload) {
    state.opportunity.influencers.splice(payload, 1);
  },

  REMOVE_OPPORTUNITY_EDIT_INFLUENCER(state, payload) {
    // const index = state.opportunity.influencers.findIndex((el) => el.id === payload);
    // const arr = [...state.opportunity.influencers];
    // state.opportunity.influencers = arr.splice(payload, 1);
    state.opportunity.influencers.splice(payload, 1);
  },

  SET_OPPORTUNITY_VIEW(state, payload) {
    state.opportunityView = payload;
  },

  SET_OPPORTUNITY_OBJECT(state, payload) {
    state.opportunity = payload;
  },

  SET_OPPORTUNITY_NAME(state, payload) {
    state.opportunity.name = payload;
  },

  SET_OPPORTUNITY_DESCRIPTION(state, payload) {
    state.opportunity.description = payload;
  },

  SET_OPPORTUNITY_TYPE_ID(state, payload) {
    state.opportunity.typeId = payload;
  },

  SET_OPPORTUNITY_STAGE_ID(state, payload) {
    state.opportunity.stageId = payload;
  },

  SET_OPPORTUNITY_TEMPERATURE_ID(state, payload) {
    state.opportunity.temperatureId = payload;
  },

  SET_OPPORTUNITY_OWNER_ID(state, payload) {
    state.opportunity.owner.id = payload;
  },

  SET_OPPORTUNITY_OWNER(state, payload) {
    state.opportunity.owner.owner = payload;
  },

  SET_OPPORTUNITY_OWNER_PERCENTAGE(state, payload) {
    state.opportunity.owner.percentage = payload;
  },

  SET_OPPORTUNITY_CO_OWNER_ID(state, payload) {
    state.opportunity.coOwner.id = payload;
  },

  SET_OPPORTUNITY_CO_OWNER_OWNER_ID(state, payload) {
    state.opportunity.coOwner.opportunityOwnerId = payload;
  },

  SET_OPPORTUNITY_CO_OWNER(state, payload) {
    state.opportunity.coOwner.owner = payload;
  },

  SET_OPPORTUNITY_CO_OWNER_PERCENTAGE(state, payload) {
    state.opportunity.coOwner.percentage = payload;
  },

  SET_OPPORTUNITY_ACCOUNT_ID(state, payload) {
    state.opportunity.accountId = payload;
  },

  SET_OPPORTUNITY_ACCOUNT(state, payload) {
    state.opportunity.account = payload;
  },

  SET_OPPORTUNITY_PRIMARY_CONTACT_ID(state, payload) {
    state.opportunity.primaryContactId = payload;
  },

  SET_OPPORTUNITY_PRIMARY_CONTACT(state, payload) {
    state.opportunity.primaryContact = payload;
  },

  SET_OPPORTUNITY_COMPETITOR_INFO(state, payload) {
    state.opportunity.competitorInfo = payload;
  },

  ADD_OPPORTUNITY_PROJECT_FOLDER(state, payload) {
    state.opportunity.projectFolderId.push(payload);
  },

  REMOVE_OPPORTUNITY_PROJECT_FOLDER(state, payload) {
    const index = state.opportunity.projectFolderId.findIndex((el) => el.projectId === payload);
    state.opportunity.projectFolderId.splice(index, 1);
  },

  SET_OPPORTUNITY_PROBABILITY(state, payload) {
    state.opportunity.estimatedPotentials.probability = payload;
  },

  SET_OPPORTUNITY_ESTIMATED_REVENUE(state, payload) {
    state.opportunity.estimatedPotentials.estimatedRevenue = payload;
  },

  SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT(state, payload) {
    state.opportunity.estimatedPotentials.estimatedGrossProfit = payload;
  },

  SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE(state, payload) {
    state.opportunity.estimatedPotentials.estGrossProfitPercentage = payload;
  },

  SET_OPPORTUNITY_BID_DUE_DATE(state, payload) {
    state.opportunity.estimatedPotentials.bidDueDate = payload;
  },

  SET_OPPORTUNITY_EST_BOOKING_DATE(state, payload) {
    state.opportunity.estimatedPotentials.estBookingDate = payload;
  },

  SET_OPPORTUNITY_EST_INSTALL_DATE(state, payload) {
    state.opportunity.estimatedPotentials.estInstallDate = payload;
  },

  SET_OPPORTUNITY_EST_INVOICE_DATE(state, payload) {
    state.opportunity.estimatedPotentials.estInvoiceDate = payload;
  },

  SET_OPPORTUNITY_CUSTOM_FIELDS(state, payload) {
    state.opportunity.customFields = payload;
  },

  SET_OPPORTUNITY_CLOSING_REASON(state, payload) {
    state.opportunity.reasonId = payload;
  },

  SET_OPPORTUNITY_REASON_STAGES(state, payload) {
    state.reasonStages = payload;
  },

  SET_OPPORTUNITY_STAGE_REASONS(state, payload) {
    state.stageReasons = payload;
  },

  CLEAR_OPPORTUNITY_STAGE_REASONS(state) {
    state.stageReasons = [];
  },

  SET_OPPORTUNITY_TAGS(state, payload) {
    state.opportunity.tags = payload;
  },

  SET_OPPORTUNITY_IS_ACTIVE(state, payload) {
    state.opportunity.isActive = payload;
  },

  SET_LEAD_SOURCE_ID(state, payload) {
    state.opportunity.leadsSourceId = payload;
  },

  CLEAR_OPPORTUNITY(state) {
    state.opportunity = {
      name: '',
      description: '',
      typeId: [],
      stageId: null,
      statusId: 1,
      temperatureId: '',
      owner: {
        id: localStorage.getItem('user_id'),
        owner: localStorage.getItem('full_name'),
        percentage: 100,
      },
      coOwner: {
        id: '',
        owner: '',
        percentage: 0,
      },
      accountId: '',
      account: '',
      primaryContactId: '',
      primaryContact: '',
      competitorInfo: '',
      influencers: [],
      projectFolderId: [],
      estimatedPotentials: {
        probability: '',
        estimatedRevenue: 0,
        estimatedGrossProfit: 0,
        estGrossProfitPercentage: 0,
        bidDueDate: null,
        estBookingDate: null,
        estInstallDate: null,
        estInvoiceDate: null,
      },
      customFields: [],
      reasonId: null,
      tags: [],
      isActive: true,
      leadsSourceId: null,
    };
  },

  CLEAR_OPPORTUNITY_VIEW(state) {
    state.opportunityView = {};
  },

  CLEAR_OPPORTUNITIES(state) {
    state.opportunities = [];
  },
};
