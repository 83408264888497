export default {
  loading: false,
  activities: [],
  calendarActivities: [],
  embeddedActivities: [],
  listActivities: [],
  activitiesCount: 0,
  activity: {
    parent: '',
    opportunityId: '',
    accountId: '',
    contactId: '',
    activityTypeId: '',
    eventTypeId: '',
    activityName: '',
    description: '',
    owner: localStorage.getItem('full_name'),
    ownerId: localStorage.getItem('user_id'),
    taskPriorityId: '',
    taskTypeId: '',
    assignedTo: '',
    isComplete: false,
    reminder: {
      remindAtId: '',
      recurringFrequencyId: '',
    },
    dueDate: null,
    meetingDate: null,
    meetingStartTime: null,
    meetingEndTime: null,
    timezoneId: '',
    file: {
      fileName: '',
      file: '',
    },
    invited: [],
    isActive: true,
    documents: [],
  },
};
