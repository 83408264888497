export default {
  SET_LOADING(state, payLoad) {
    state.isLoading = payLoad;
  },

  SET_ORDER_QUOTE_FINANCIALS(state, payload) {
    state.orderQuoteFinancials = payload;
  },

  SET_ACCOUNT_ORDER_QUOTE_FINANCIALS(state, payload) {
    state.accountOrderQuoteFinancials = payload;
  },

  SET_ORDER_QUOTE_FINANCIALS_SUMMATION(state, payload) {
    state.orderQuoteFinancialsSummation = payload;
  },

  CLEAR_ORDER_QUOTE_FINANCIALS(state) {
    state.orderQuoteFinancials = [];
  },

  CLEAR_ACCOUNT_ORDER_QUOTE_FINANCIALS(state) {
    state.accountOrderQuoteFinancials = [];
  },
};
