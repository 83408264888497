export default {
  isLoading: false,
  documents: [],
  documentsCount: 0,
  document: null,
  files: [],
  file: {
    fileName: '',
    documentId: '',
  },
};
