import $http from '@/plugins/axios';

export default {
  /**
   * fetchLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeads({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/leadsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_LEADS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchDrilldownLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchDrilldownLeads({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/leadsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_DRILLDOWN_LEADS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLead
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLead({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: `/leads/${payload}`, method: 'GET' });
      await commit('SET_LEAD_VIEW', response.data.data);
      await commit('SET_LEAD', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchLeadPermissions
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchLeadPermissions({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Organization({ url: `/leads/${payload}/can-edit`, method: 'GET' });
      await commit('SET_READ_PERMISSIONS', response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountInfluencing
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountInfluencing({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/accounts/opportunities?opportunityaccountid=${payload.accountId}&page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_INFLUENCING', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createLeadInfluencer
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createLeadInfluencer({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.leadId}/partners`,
        data: payload.influencer,
        method: 'POST',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * removeLeadInfluencer
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async removeLeadInfluencer({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/opportunities/${payload.leadId}/partners/${payload.accountId}`,
        method: 'DELETE',
      });
      commit('REMOVE_LEAD_EDIT_INFLUENCER', payload.influencerId);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * createLead
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async createLead({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: '/leads', data: payload, method: 'POST' });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * updateLead
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async updateLead({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/leads/${payload.leadId}`,
        data: payload.lead,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * deleteLead
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async deleteLead({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({ url: `/leads/${payload.id}`, method: 'DELETE' });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * convertToOpportunity
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async convertToOpportunity({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await $http.Leads({
        url: `/leads/${payload}/convert-to-opportunity`,
        method: 'PUT',
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchAccountLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchAccountLeads({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/accounts/${payload.accountId}/leads?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_LEADS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  /**
   * fetchContactLeads
   *
   * @param {*} { commit }
   * @param {*} payload
   * @return {*}
   */
  async fetchContactLeads({ commit }, payload) {
    try {
      const response = await $http.Leads({
        url: `/leadsByUserId?page=${payload.page}&size=${payload.perPage}&sort=${payload.sort}&contactid=${payload.contactId}${payload.openSwitch}${payload.globalSearch}${payload.filters}`,
        method: 'GET',
      });
      commit('SET_LEADS', response.data.content);
      return response.data;
    } catch (error) {
      throw error.response.data;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
